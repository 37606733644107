import * as yup from 'yup';
import moment from 'moment';
import { INVALID_FORMAT_ENG, MANDATORY_FIELD } from '../../../../Constants';

export const validationSchema = [
  yup.object().shape({
    purpose: yup.string().required(MANDATORY_FIELD),
    otherPurpose: yup.string().when('purpose', {
      is: (val) => val === 'Other',
      then: yup.string().required(MANDATORY_FIELD),
    }),
    specificReason: yup.string().when(['purpose', 'otherPurpose'], {
      is: (purpose, otherPurpose) => purpose === 'Other' && otherPurpose === 'Other, specify',
      then: yup.string().required(MANDATORY_FIELD),
    }),
    effective_day: yup.string().required(MANDATORY_FIELD),
    effective_month: yup.string().required(MANDATORY_FIELD),
    effective_year: yup.string().required(MANDATORY_FIELD),
    effective_date: yup.string().required(MANDATORY_FIELD),
  }),
  yup.object().shape(
    {
      firstName: yup
        .string()
        .required(MANDATORY_FIELD)
        .matches(/^[A-Za-zÑñÁáÉéÍíÓóÚúü]+( [A-Za-zÑñÁáÉéÍíÓóÚúü]+)*$/, INVALID_FORMAT_ENG),
      middleName: yup.string().matches(/^[A-Za-zÑñÁáÉéÍíÓóÚúü]+$/, INVALID_FORMAT_ENG),
      lastName: yup
        .string()
        .required(MANDATORY_FIELD)
        .matches(/^[A-Za-zÑñÁáÉéÍíÓóÚúü]+$/, INVALID_FORMAT_ENG),
      mothersLastName: yup.string().matches(/^[A-Za-zÑñÁáÉéÍíÓóÚúü]+$/, INVALID_FORMAT_ENG),
      socialSecurityNumber: yup
        .string()
        .min(11, '* Social security number should have 9 digits')
        .matches(/^[\d|x]{3}-?[\d|x]{2}-?\d{4}$/, INVALID_FORMAT_ENG)
        .required(MANDATORY_FIELD),
      mobileNumberAreacode: yup
        .string()
        .matches(/^\d*$/, INVALID_FORMAT_ENG)
        .min(3, '* Areacode should have 3 digits')
        .required(INVALID_FORMAT_ENG),
      mobileNumber: yup
        .string()
        .matches(/^[\d-() ]*$/, INVALID_FORMAT_ENG)
        .min(10, '* Mobile number should have 10 digits')
        .required(MANDATORY_FIELD),
      altMobileNumber: yup
        .string()
        .matches(/^[\d-() ]*$/, INVALID_FORMAT_ENG)
        .min(10, '* Phone number should have 10 digits'),
      altMobileNumberAreacode: yup.string().when('altMobileNumber', {
        is: (val) => val !== undefined && val !== '',
        then: yup
          .string()
          .matches(/^\d*$/, INVALID_FORMAT_ENG)
          .min(3, '* Areacode should have 3 digits')
          .required(INVALID_FORMAT_ENG),
        otherwise: yup.string(),
      }),
      loanNumber: yup
        .string()
        .ensure()
        .when('dob_date', {
          is: (val) => val === '',
          then: yup.string().required(MANDATORY_FIELD).matches(/^\d*$/, INVALID_FORMAT_ENG),
          otherwise: yup.string().matches(/^\d*$/, INVALID_FORMAT_ENG),
        }),
      dob_day: yup
        .string()
        .ensure()
        .when('loanNumber', {
          is: (val) => val === '',
          then: yup.string().required(MANDATORY_FIELD),
          otherwise: yup.string(),
        }),
      dob_month: yup
        .string()
        .ensure()
        .when('loanNumber', {
          is: (val) => val === '',
          then: yup.string().required(MANDATORY_FIELD),
        }),
      dob_year: yup
        .string()
        .ensure()
        .when('loanNumber', {
          is: (val) => val === '',
          then: yup.string().required(MANDATORY_FIELD),
        }),
      dob_date: yup
        .string()
        .ensure()
        .when('loanNumber', {
          is: (val) => val === '',
          then: yup
            .string()
            .required(MANDATORY_FIELD)
            .test(
              'dob_date',
              '* You must be 18 years or older',
              (value) => moment().diff(moment(value), 'years') >= 18
            ),
          otherwise: yup.string(),
        }),
      personEmail: yup
        .string()
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, INVALID_FORMAT_ENG)
        .required(MANDATORY_FIELD),
    },
    [['loanNumber', 'dob_date']]
  ),
  yup.object().shape({
    consent: yup.boolean().oneOf([true], MANDATORY_FIELD),
    consentEmail: yup.boolean().oneOf([true], MANDATORY_FIELD),
  }),
];
export const validationSchemaForServiceToMyAccount = yup.object().shape({
  firstName: yup
    .string()
    .required(MANDATORY_FIELD)
    .matches(/^[A-Za-zÑñÁáÉéÍíÓóÚúü]+( [A-Za-zÑñÁáÉéÍíÓóÚúü]+)*$/, INVALID_FORMAT_ENG),
  lastName: yup
    .string()
    .required(MANDATORY_FIELD)
    .matches(/^[A-Za-zÑñÁáÉéÍíÓóÚúü]+$/, INVALID_FORMAT_ENG),
  mobileNumberAreacode: yup
    .string()
    .matches(/^\d*$/, INVALID_FORMAT_ENG)
    .min(3, '* Areacode should have 3 digits')
    .required(INVALID_FORMAT_ENG),
  mobileNumber: yup
    .string()
    .matches(/^[\d-() ]*$/, INVALID_FORMAT_ENG)
    .min(10, '* Mobile number should have 10 digits')
    .required(MANDATORY_FIELD),
  socialSecurityNumber: yup
    .string()
    .min(11, '* Social security number should have 9 digits')
    .matches(/^[\d|x]{3}-?[\d|x]{2}-?\d{4}$/, INVALID_FORMAT_ENG)
    .required(MANDATORY_FIELD),
  dob_day: yup.string().required(MANDATORY_FIELD),
  dob_month: yup.string().required(MANDATORY_FIELD),
  dob_year: yup.string().required(MANDATORY_FIELD),
  dob_date: yup
    .string()
    .required(MANDATORY_FIELD)
    .test(
      'dob_date',
      '* You must be 18 years or older',
      (value) => moment().diff(moment(value), 'years') >= 18
    ),
  personEmail: yup.string().email('* Invalid email').required(MANDATORY_FIELD),
  accountType: yup.string().required(MANDATORY_FIELD),
  accountNumber: yup
    .string()
    .matches(/^\d*$/, INVALID_FORMAT_ENG)
    .min(10, '* Account number should have atleast 10 digits'),
});
export const validationSchemaForGeneralInfo = yup.object().shape({
  firstName: yup
    .string()
    .required(MANDATORY_FIELD)
    .matches(/^[A-Za-zÑñÁáÉéÍíÓóÚúü]+( [A-Za-zÑñÁáÉéÍíÓóÚúü]+)*$/, INVALID_FORMAT_ENG),
  lastName: yup
    .string()
    .required(MANDATORY_FIELD)
    .matches(/^[A-Za-zÑñÁáÉéÍíÓóÚúü]+$/, INVALID_FORMAT_ENG),
  mobileNumberAreacode: yup
    .string()
    .matches(/^\d*$/, INVALID_FORMAT_ENG)
    .min(3, '* Areacode should have 3 digits')
    .required(INVALID_FORMAT_ENG),
  mobileNumber: yup
    .string()
    .matches(/^[\d-() ]*$/, INVALID_FORMAT_ENG)
    .min(10, '* Mobile number should have 10 digits')
    .required(MANDATORY_FIELD),
  personEmail: yup.string().email(INVALID_FORMAT_ENG).required(MANDATORY_FIELD),
  accountType: yup.string().required(MANDATORY_FIELD),
});
