export const ARIA_LABEL_DOB_DAY =
  'Date Of Birth Required Select Day in Date Of Birth dropdown Required';
export const CHOOSE_START_DATE_ENG = 'Choose start date';
export const CHOOSE_START_DATE_ESP = 'Selecciona la fecha de inicio';
export const CHOOSE_END_DATE_ENG = 'Choose end date';
export const CHOOSE_END_DATE_ESP = 'Selecciona la fecha de finalización';
export const DATE_PICKER_ICON = 'Date Picker Icon';
export const SKIP_TEXT_ENG = 'I’ll skip this step'; // need to check once
export const SKIP_TEXT_ESP = 'Omitiré este paso';
export const ENTER_CARD_NUMBER_OPTIONAL_ENG =
  'And finally, enter the card number you will be using during the trip. (This is optional)';
export const ENTER_CARD_NUMBER_OPTIONAL_ESP =
  'entra el número de la tarjeta que utilizarás durante el viaje. (Esto es opcional)';
export const ENTER_CARD_NUM_OPTIONAL_ESP =
  'Para finalizar, entra el número de tarjeta que utilizarás durante el viaje. (Esto es opcional)';
export const ENTER_CARD_NUM_ENG =
  'enter the card number you will be using during the trip. (This is optional)';
export const ENTER_CARD_NUM_ESP =
  'entra el número de tarjeta que utilizarás durante el viaje. (Esto es opcional)';
export const ENTER_CARD_NUMBER_ENG = 'Enter card number';
export const ENTER_CARD_NUMBER_ESP = 'Ingresar número de tarjeta';
export const ENTER_ACCOUNT_NUMBER_ENG = 'Enter account number';
export const ENTER_ACCOUNT_NUMBER_ESP = 'Ingresar número de cuenta';
export const CONNECTING_LIVE_AGENT = 'Connecting you to a live agent';
export const HAS_ENDED = 'has ended!';
export const HA_TERMINADO = 'ha terminado!';
export const EN_US = 'en-US';
export const EN_ES = 'en-ES';
export const ES_PR = 'es-PR';
export const ATTACHMENT_FILE = 'attachment file';
export const UPLOAD_DOC_HERE = 'Upload your document here';
export const TYPE_A_MESSAGE_ENG = 'Type a message';
export const TYPE_A_MESSAGE_ESP = 'Escribe un mensaje';
export const LANGUAGE_SELECTION = 'language selection';
export const ESP = 'ESP';
export const ENG = 'ENG';
export const ORIENTAL_BANK_LOGO = 'oriental chat logo';
export const KNOW_TO_CHAT_NO = 'KNOW_TO_CHAT_NO';
export const GREET_INTENT = 'Greet_Intent';
export const ZERO_SEVEN_ONE = 'ZERO_SEVEN_ONE';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'; // check
export const CB_LANGUAGE_SWITCH_ENGLISH = 'CB_LANGUAGE_SWITCH_ENGLISH';
export const CB_LANGUAGE_SWITCH_SPANISH = 'CB_LANGUAGE_SWITCH_SPANISH';
export const CANCEL_CHAT = 'CANCEL_CHAT';
export const PUERTO_RICO_WEBFORM = 'PUERTO_RICO_WEBFORM'; // check
export const USVI_WEBFORM = 'USVI_WEBFORM';
export const ZERO_SEVEN_TWO = 'ZERO_SEVEN_TWO';
export const SHOW_FEEDBACK = 'Show Feedback';
export const ADD_DTLS_NO = 'ADD_DTLS_NO';
export const LIVE_AGENT_SERVICE_ACCOUNT = 'live_agent_service_account';
export const LIVE_AGENT_GENERAL_INFO = 'live_agent_general_info';
export const NO_COUNTRY_TRAVEL = 'NO_COUNTRY_TRAVEL';
export const CHAT_INTENT = 'CHAT_INTENT';
export const CREDIT = 'CREDIT';
export const REQUEST_CALL_BACK_ENG = 'Request a call back';
export const REQUEST_CALL_BACK_ESP = 'Solicitar que me devuelvan la llamada';
export const IGNORE_MESSAGE = 'IgnoreMessage';
export const IN_PROGRESS = 'Inprogress';
export const FINALLY_YOUR_ACC_NUM_ENG = 'And finally, your account number (this is optional)';
export const FINALLY_YOUR_ACC_NUM_ESP = 'Para finalizar, tu número de cuenta (esto es opcional)';
export const YOUR_ACC_NUM_ENG = 'Your account number (this is optional)';
export const YOUR_ACC_NUM_ESP = 'Tu número de cuenta (esto es opcional)';
export const PROVIDE_ACC_NUM_ENG = 'Now provide me your account number: (this is optional)';
export const PROVIDE_ACC_NUM_ESP = 'ahora proveéme tu número de cuenta: (esto es opcional)';
export const ESTO_ES_OPTIONAL = '(esto es opcional)';
export const ESTO_ES_OPCIONAL = '(Esto es opcional)';
export const ACCOUNT_NO = 'AccountNo';
export const CARD_NO = 'CardNo';
export const LOAN_ACCOUNT_NO = 'LoanAcctNum';
export const CARD_ACC_NO = 'CCAcctNum';
export const DB_CARD_ACC_NO = 'PayfromAcctNum';
export const LETS_CONTINUE_ENG = 'Let’s continue!';
export const LETS_CONTINUE_ESP = '¡Continuemos!';
export const SELECT_START_END_DATE_ENG = 'select the travel start & end dates:';
export const SELECT_START_END_DATE_ESP = 'selecciona la fecha de inicio y terminación del viaje:';
export const SELECT_START_END_DATE_ENG_CAPS = 'Select the travel start & end dates:';
export const SELECT_START_RETURN_DATE_ENG = 'select the start and return date of your trip:';
export const SELECT_START_RETURN_DATE_ENG_CAPS = 'Select the start and return date of your trip:';
export const SELECCIONA_START_END_DATE_ESP =
  'selecciona la fecha de inicio y terminación del viaje:';
export const SELECCIONA_START_RETURN_DATE_ESP =
  'selecciona la fecha de inicio y de regreso de tu viaje:';
export const TRAVEL_DATES = 'TravelDates';
export const REFER_TO_THIS_IMAGE = 'Refer to this image:'; // check this
export const REFER_TO_THIS_IMAGE_ESP = 'Ver la siguiente imagen:';
export const ALL_AGENTS_BUSY_EST_TIME_ENG =
  'All our agents are busy now. The estimated wait time is';
export const ALL_AGENTS_BUSY_EST_TIME_ESP =
  'Todos nuestros agentes están ocupados. El tiempo estimado de espera es';
export const ESTIMATED_WAIT_TIME_IS = 'No problem, the estimated wait time is'; // check in app.js
export const ESTIMATED_WAIT_TIME_IS_ESP = 'Por supuesto, el tiempo estimado de espera es';
export const SORRY_AGENTS_ARE_BUSY_ENG = 'I am sorry! All our agents are busy now';
export const SORRY_AGENTS_ARE_BUSY_ESP = 'Lo siento mucho, todos nuestros agentes están ocupados';
export const SORRY_AGENTS_BUSY_ESP = '¡Lo siento! Todos nuestros agentes están ocupados ahora';
export const WANT_CONTACT_BACK_ENG = 'Do you want us to contact you back via phone call?';
export const WANT_CONTACT_BACK_ESP =
  'Algo no anda bien. ¿Te parece bien si te contactamos por llamada telefónica?';
export const REGISTER_AS_CUSTOMER_ENG = 'Register as customer';
export const REGISTER_AS_CUSTOMER_ESP = 'Registrarse como cliente';
export const END_CHAT_FEEDBACK_ENG = 'End Chat & Feedback';
export const END_CHAT_FEEDBACK_ESP = 'Finalizar chat y comentarios';
export const END_CHAT_ENG = 'End Chat';
export const END_CHAT_ESP = 'Cerrar Chat';
export const CANCEL_THIS_PROCESS_ENG = 'Cancel this process';
export const CANCEL_THIS_PROCESS_ESP = 'Cancelar este proceso';
export const FOOTER_TEXT = '© 2021 Orientalbank.com. All Rights Reserved. FDIC Member.';
export const FOOTER_TEXT_SMALL = '© 2021 orientalbank.com. All Rights Reserved. FDIC Member.';
export const TRY_SOMETHING_ELSE_ENG = 'No problem, ready to help you, try entering something else.';
export const TRY_SOMETHING_ELSE_ESP =
  'No hay problema, listo para ayudarte, intenta ingresar otra cosa.';
export const LOOKING_INFO_ABOUT_ENG = 'You are looking information about';
export const LOOKING_INFO_ABOUT_ESP = 'Buscas información sobre';
export const MOBILE_NUMBER = 'mobileNumber';
export const ALT_MOBILE_NUMBER = 'altMobileNumber';
export const OTHER_MOBILE_NUMBER = 'otherPersonMobileNumber';
export const OTHER_ALT_MOBILE_NUMBER = 'otherPersonAltMobileNumber';
export const ALT_MOBILE_NUM_AREA_CODE = 'altMobileNumberAreacode';
export const NEED_CONTACT_INFO_ENG =
  'To assist you better, I will need your contact information before connecting you with an agent. ';
export const NEED_CONTACT_INFO_ESP =
  'Para ayudarte mejor, necesitaré tu información de contacto antes de conectarte con un agente. ';
export const CONSENT_FOR_CALLS_ENG =
  'By providing this information, you are giving your consent for Oriental Bank to communicate with you through calls, SMS, and emails for this request.';
export const CONSENT_FOR_CALLS_ESP =
  'Al compartir esta información autorizas a Oriental Bank a comunicarse contigo mediante llamadas, email y mensaje de texto para esta solicitud.';
export const TELL_US_ABOUT_YOURSELF = 'Perfect, tell us about yourself:';
export const FIRST_NAME = 'First Name';
export const ENTER_FIRST_NAME = 'Enter first name';
export const LAST_NAME = 'Last Name';
export const ENTER_LAST_NAME = 'Enter last name';
export const LET_US_KNOW_EMAIL = 'Now, let us know your email to send you the document';
export const EMAIL_ADDRESS = 'Email Address';
export const ENTER_EMAIL_ADDRESS = 'Enter email address';
export const ENTER_EMAIL_ADDRESS_ESP = 'Escribe tu email';
export const MOBILE_NUMBER_CAPS = 'Mobile Number';
export const ENTER_MOBILE_NUMBER = 'Enter phone number';
export const LEAVE_A_MESSAGE_ENG = 'Leave a message';
export const LEAVE_A_MESSAGE_ESP = 'Enviar un mensaje';
export const CONNECT_LIVE_AGENT_ENG = 'No, please try to connect me with a live agent again.';
export const CONNECT_LIVE_AGENT_ESP = 'No, intenta nuevamente conectarme con un agente en vivo.';
export const CONTINUE_WITH_CHATBOT_ENG = 'No, continue with the chatbot';
export const CONTINUE_WITH_CHATBOT_ESP = 'No, continuar con el chatbot';
export const ACCOUNT_TYPE_ENG = 'Account Type';
export const ACCOUNT_TYPE_ESP = 'Tipo de cuenta';
export const DD = 'DD';
export const MMM = 'MMM';
export const YYYY = 'YYYY';
export const PROTECT_YOUR_INFO_ENG =
  'To protect your information and provide you a full experience we need to validate some information. This information will be sent exclusively to a live agent and will not be shown in the chat history.';
export const PROTECT_YOUR_INFO_ESP =
  'Para proteger tu información y brindar una experiencia completa, necesitamos validar cierta información. Esta información se enviará exclusivamente a un agente en vivo y no se mostrará en el historial de chat.';
export const SHARE_CONTACT_INFO_ENG = 'Let’s get started! Share your contact information.';
export const SHARE_CONTACT_INFO_ESP = '¡Empecemos! Comparte tu información de contacto.';
export const MORE_ABOUT_YOU_ENG = 'More about you!';
export const MORE_ABOUT_YOU_ESP = '¡Mas sobre ti!';
export const ACCOUNT_NUMBER_ENG = 'Account Number';
export const ACCOUNT_NUMBER_ESP = 'Número de cuenta';
export const SEND_ENG = 'Send';
export const SEND_ESP = 'Enviar';
export const CONTINUE_AS_GUEST_ENG = 'Continue as guest';
export const CONTINUE_AS_GUEST_ESP = 'Continuar como invitado';
export const BACK_ENG = 'Back';
export const BACK_ESP = 'Atrás';
export const GO_BACK_TO_CHATBOT_ENG = 'Go back to chatbot';
export const GO_BACK_TO_CHATBOT_ESP = 'Regresar al chatbot';
export const WAIT_FOR_LIVE_AGENT_ENG = 'I will wait for a live agent';
export const WAIT_FOR_LIVE_AGENT_ESP = 'Esperaré por un agente en vivo';
export const EST_WAIT_TIME_ENG = 'The estimated wait time is more than 5 minutes.';
export const EST_WAIT_TIME_ESP = 'El tiempo estimado de espera es más de 5 minutos.';
export const ESTIMATED_WAIT_TIME_ENG = 'The estimated wait time is more than 5';
export const ESTIMATED_WAIT_TIME_ESP = 'El tiempo estimado de espera es más de 5';
export const SUBMIT_FEEDBACK_ENG = 'Submit Feedback';
export const SUBMIT_FEEDBACK_ESP = 'Enviar comentarios';
export const BACK_TO_CHAT_ENG = 'Back to chat';
export const BACK_TO_CHAT_ESP = 'Volver al chat';
export const HAVE_MORE_TO_SAY_ENG = 'Have more to say?';
export const HAVE_MORE_TO_SAY_ESP = '¿Tienes más que decir?';
export const SEND_CHAT_TRANSCRIPT_ENG = 'Send chat transcript via email.';
export const SEND_CHAT_TRANSCRIPT_ESP = 'Enviar por email el historial del chat.';
export const ERROR_MESSAGE_TRANSCRIPT_ENG = '* Enter an email to receive your chat transcript.';
export const ERROR_MESSAGE_TRANSCRIPT_ESP =
  '* Escribe tu email para recibir el historial del chat.';
export const CONNECTING_TO_LIVR_AGENT_ENG = `now I'm connecting you to a live agent.`;
export const CONNECTING_TO_LIVR_AGENT_ESP = 'ahora te estaré conectando con un agente en vivo.';
export const NEED_ANYTHING_ELSE_ENG = 'Need help with anything else?';
export const NEED_ANYTHING_ELSE_ESP = 'Estoy aquí para ayudarte. ¿Necesitas algo más?';
export const CONTACT_YOU_SOON_ENG = 'we will contact you soon to finalize your request.';
export const CONTACT_YOU_SOON_ESP = 'estaremos comunicando contigo.';
export const SOON_CONTACT_YOU_ESP = 'pronto nos estaremos comunicando contigo.';
export const CONNECT_ME_TO_LIVE_AGENT_ENG = 'No, try again to connect me with a Live Agent';
export const CONNECT_ME_TO_LIVE_AGENT_ESP =
  'No, intenta nuevamente conectarme con un Agente en vivo';
export const CB_GREETING_INTENT_ZERO_ONE_ENG = 'CB_GreetingIntent_zero_one';
export const CB_GREETING_INTENT_ZERO_ONE_ESP = 'CB_GreetingIntent_zero_one_esp';
export const WILL_CONTACT_YOU_SOON_ENG = 'we will contact you soon.';
export const WILL_CONTACT_YOU_SOON_ESP = 'pronto nos comunicaremos contigo.';
export const ALL_AGENTS_ARE_BUSY_ENG = 'All our agents are busy now';
export const ALL_AGENTS_ARE_BUSY_ESP = 'Todos nuestros agentes están ocupados ahora';
export const SHARE_MY_CONTACT_DETAILS_ENG = 'Share my contact details';
export const SHARE_MY_CONTACT_DETAILS_ESP = 'Compartir mis datos de contacto';
export const MORE_THAN_TEN_MIN_ENG = 'It’s been more than 10 minutes waiting';
export const MORE_THAN_TEN_MIN_ESP = 'Han pasado más de 10 minutos esperando';
export const FEEDBACK_WILL_HELP_ENG =
  'Your feedback will help me to be better. Did you get what you were looking for?';
export const FEEDBACK_WILL_HELP_ESP =
  'Tu opinión me ayudará a mejorar. ¿Conseguiste lo que buscabas?';
export const OFCOURSE_WHAT_YOU_NEED_ENG = 'Of course! What do you need?';
export const WHAT_YOU_NEED_ENG = 'No problem, just tell me what you need!';
export const WHAT_YOU_NEED_ESP = '¡Por supuesto! ¿Qué necesitas?';
export const WHAT_TO_CHANGE_LANGUAGE_ENG =
  'Yes, we can chat in English. Are you sure you want to change the language?';
export const WHAT_TO_CHANGE_LANGUAGE_ESP =
  'Sí, podemos chatear en español. ¿Quieres cambiar el idioma?';
export const DESTINATION = 'Destination';
export const WORKING_HOURS_MON_TO_FRI_ENG =
  'Our Live Agent working hours are from Monday to Friday'; // check
export const WORKING_HOURS_MON_TO_FRI_ESP =
  'El horario de nuestros agentes en vivo es de lunes a viernes';
export const LIVE_AGENT_CHAT_ENDED_ENG = 'Your chat with the Live Agent has ended!';
export const LIVE_AGENT_CHAT_ENDED_ESP = '¡Tu chat con el agente en vivo ha terminado!';
export const CHAT_WITH_BOT_ENDED_ENG = 'Your chat with the BOT has ended!';
export const CHAT_WITH_BOT_ENDED_ESP = '¡Tu chat con la BOT ha terminado!';
export const PLEASE_TELL_YOUR_NAME_ENG = 'Are you there? Please tell me your name:';
export const PLEASE_TELL_YOUR_NAME_ESP = '¿Estás ahí? Por favor dime tu nombre:';
export const INVALID_FORMAT_ENG = '* Invalid format';
export const MANDATORY_FIELD = '* Mandatory Field';
export const INVALID_MOBILE_NUM_ENG = '* Invalid mobile number';
export const INVALID_FORMAT_ESP = '* Formato invalido';
export const SOCIAL_SECURITY_NUMBER = 'socialsecurityNumber';
export const INVALID_SSN = '* Invalid social security number';
export const INVALID_ACC_NUM = '* Invalid account number';
export const INVALID_NUMBER = '* Invalid number';
export const INVALID_EMAIL = '* Invalid email address';
export const PLEASE_ENTER_NINE_DIGITS_ENG = '* Please enter the 9 digits routing number';
export const PLEASE_ENTER_NINE_DIGITS_ESP = '* Por favor entra los 9 dígitos número de ruta';
export const PLEASE_ENTER_CARD_NUMBER_ENG = 'Please enter the 16 digits of your card.';
export const PLEASE_ENTER_CARD_NUMBER_ESP = 'Por favor entra los 16 dígitos de tu tarjeta.';
export const INVALID_DATE_FORMAT_ENG = '* Invalid date format (DD/MM/YYYY)';
export const INVALID_DATE_FORMAT_ESP = '* Formato invalido (DD/MM/YYYY)';
export const END_DATE_GREATER_THAN_START_DATE_ENG =
  '* End date should be greater than or equal to start date';
export const END_DATE_GREATER_THAN_START_DATE_ESP =
  '* La fecha de finalización debe ser mayor o igual que la fecha de inicio';
export const TRAVEL_ACC_NUM = 'TravelAcctNum';
export const ACC_NUM = 'AcctNum';
export const MORE_THAN_FIVE_ENG = 'more than 5';
export const MORE_THAN_FIVE_ESP = 'más de 5';
export const SOMETHING_WENT_WRONG_ENG =
  'Oops! Something went wrong. Do you want us to contact you back via phone call?';
export const SOMETHING_WENT_WRONG_ESP =
  '¡Ups! Algo no anda bien. ¿Te parece bien si te contactamos por llamada telefónica?';
export const TRY_AGAIN_LATER_ENG =
  'Oops! Something went wrong, try again later. If you need immediate assistance, click the link below:';
export const TRY_AGAIN_LATER_ESP =
  '¡Ups! Parece que algo no anda bien, inténtalo de nuevo más tarde. Si necesitas asistencia inmediata, haz clic en el siguiente enlace:';
export const CONTACT_US_ENG = 'Contact us';
export const CONTACT_US_ESP = 'Contáctanos';
export const INVALID_DATE_ENG = '* Invalid Date';
export const DATE_NOT_GREATER_THAN_30_DAYS = '* Date should not be greater than 30 days';
export const DATE_SHOULD_NOT_BE_IN_PAST = '* Date should not be in the past';
export const EFFECTIVE_DATE_TOOLTIP =
  'To prevent your cancellation balance from expiring during the cancellation process, we recommend that you choose an effective date greater than 10 days from today.';
export const SELECT_YEAR_ARIA_LABEL = 'Select Year in Effective Date dropdown Required';
export const SELECT_MONTH_ARIA_LABEL = 'Select Month in Effective Date dropdown Required';
export const EFFECTIVE_DATE = 'Effective Date';
export const LETS_START_HEADING = 'letsStartheading';
export const LETS_GET_STARTED = 'Let’s get started! What is the purpose of this request?*';
export const REFINANCING_MY_PROPERTY = 'I am refinancing my property'; // check for value place
export const BUYING_SELLING_PROCESS = 'I am in a buying/selling process'; // check for value place
export const MAKE_LAST_PAYMENT = 'I will make the last payment'; // check for value place
export const OTHER = 'Other'; //  check in step one
export const PERSONAL_PURPOSE = 'Personal purpose';
export const OTHER_SPECIFY = 'Other, specify';
export const CHOOSE_SPECIFIED_PAYOFF_DATE = 'Choose the specified Payoff date*:';
export const REQUIRED = 'Required';
export const AREA_LABEL_EFFECTIVE_DATE = 'Effective Date : *';
export const AREA_LABEL_EFFECTIVE_DAY = 'Select Day in Effective Date dropdown Required';
export const DATE_PICKER = 'Date Picker';
export const MIN_AGE_MANDATORY = '* You must be 18 years or older';
export const DATE_OF_BIRTH = 'Date of Birth';
export const AREA_LABEL_DOB_MONTH = 'Select Month in Date Of Birth dropdown Required';
export const AREA_LABEL_DOB_YEAR = 'Select Year in Date Of Birth dropdown Required';
export const SHOW_HIDE_ICON = 'show hide icon';
export const ERROR_HEADER = 'Error Header';
export const DOWNLOAD_PAYOFF_FORM = 'Download Payoff Form';
export const GO_TO_ORIENTALBANK_COM = 'Go to orientalbank.com';
export const PAYOFF_STATEMENT_REQUEST = 'Payoff Statement Request';
export const HEADER_DESCRIPTION =
  'We will be ready to deliver your Payoff Statement within the next 5 business days, after you submit the request.';
export const CONTINUE = 'Continue';
export const CLOSE_AND_RETURN_TO_CHAT = 'Close and return to chat';
export const THANKYOU_HEADER = 'Thank You Header';
export const PROVIDE_YOUR_CONSENT = 'Provide your consent';
export const CONSENT_DESC1 =
  'This application has a $10.00 cost. I consent for this amount to be included as an additional charge to my mortgage loan. The Payoff Statement will be sent within the next 5 business days to the chosen delivery method. If you do not receive the document in the stipulated time, please notify it by calling 787.767.8585.';
export const CONSENT_DESC2 =
  'The first payoff satement request and any update or correction to that original request will be issued at no cost to the client. Any additional payoff statement requests within a six (6) month period will be charged at $25.00.  I consent for this amount to be included as an additional charge to my mortgage loan. The Payoff Statement will be sent within the next 5 business days to the chosen delivery method. If you do not receive the document in the stipulated time, please notify it by calling 787.767.8585.';
export const CONSENT_EMAIL_INFO =
  'I consent to receive this payoff statement electronically. This consent applies solely and exclusively to the sending of this request. I acknowledge that I have the right to withdraw this electronic consent at any time and receive a paper copy of the payoff statement. To withdraw consent, request a copy of the document and change the email address, I must contact 787.767.8585 or send an email to servicio.hipotecas@orientalbank.com. We will send your payoff statement in PDF format. A PDF reader program may be required to view and print PDF files.';
export const MIDDLE_INITINAL = 'Middle Initial';
export const ENTER_MIDDLE_INITINAL = 'Enter middle initial';
export const MOTHERS_LAST_NAME = 'Mother`s Last Name';
export const ENTER_SECOND_LAST_NAME = 'Enter second last name';
export const LOAN_NUMBER = 'Loan Number';
export const ENTER_LOAN_NUMBER = 'Enter loan number';
export const AREA_CODE = 'Area Code';
export const ALTERNATE_PHONE = 'Alternate Phone';
export const LIVE_AGENT_ICON = 'live agent icon';
export const HI = 'Hi';
export const HOLA = 'Hola';
export const MOB_NUM = 'MobNum';
export const CUSTOMER_MOBILE = 'customerMobile';
export const PRIMARY_PHONE_NUMBER = 'PrimaryPhoneNumber';
export const LOAN_ACCT_NUM = 'LoanAcctNum';
export const PAY_FROM_ACCT_NUM = 'PayfromAcctNum';
export const ROUTING_NUM = 'RoutingNum';
export const LAST_FOUR_SSN = 'LastFourSSN';
export const LASTFOUR_SSN = 'Last4SSN';
export const LNAMT_TO_PAY = 'LNAmttopay';
export const CCAMT_TO_PAY = 'CCAmttopay';
export const OTHER_AMOUNT = 'OtherAmount';
export const CCACCT_NUM = 'CCAcctNum';
export const CUSTOMER_EMAIL = 'customerEmail';
export const EMAIL = 'Email';
export const MESSAGE_BODY = 'MessageBody';
export const FULL_NAME = 'FullName';
export const CUSTOMER_NAME = 'customerName';
export const PAYMENT_ACC_NUM = 'PaymentAcctNum';
export const SSN_SSN = 'SSN';
export const CUSTOMER_MESSAGE = 'customerMessage';
export const ADDITIONAL_DETAILS = 'Additionaldetails';
export const MOBILE = 'Mobile';
export const ATTACHMENT_LIST = 'ATTACHMENT_LIST';
export const SELECT_DIRA_MSG_ENG =
  'You want to withdraw funds from a Traditional IRA or Diversified Growth IRA, what is the reason for the withdraw?';
export const SELECT_DIRA_MSG_ESP =
  'Deseas distribuir fondos de una IRA Tradicional, escoge la razón para el retiro';
export const SELECT_EIRA_MSG_ENG =
  'You want to withdraw funds from an Educational IRA, what is the reason for the withdraw?';
export const SELECT_EIRA_MSG_ESP =
  'Deseas distribuir fondos de una IRA Educativa, ¿Cuál es la razón para el retiro?';
export const TRAVEL_RISTRICT_DESTINATION_MESG_EN =
  'Do you want to know if your destination is restricted?';
export const TRAVEL_RISTRICT_DESTINATION_MESG_ES = '¿Quieres saber si tu destino está restringido?';
export const DEVICE_IPHONE = 'DEVICE_TYPE_IPHONE';
export const DEVICE_ANDROID = 'DEVICE_TYPE_ANDROID';
export const CANCEL_ENG = 'Cancel';
export const CANCEL_ESP = 'Cancelar';
export const REMOVE_ALL_ENG = 'Remove All';
export const REMOVE_ALL_ESP = 'Borrar todos los documentos';
export const CONFIRM_ENG = 'Confirm';
export const CONFIRM_ESP = 'Confirmar';
export const UNDO_REMOVED_FILES_ENG = 'Undo Removed {num} Files';
export const UNDO_REMOVED_FILES_ESP = 'Recuperar los {num} documentos borrados';
export const PLEASE_UPLOAD_DOCUMENTS_ENG = 'Please Upload Documents';
export const PLEASE_UPLOAD_DOCUMENTS_ESP = 'Cargar Documentos';
export const SELECT_FILES_ENG = 'Select Files';
export const SELECT_FILES_ESP = 'Seleccionar documentos';
export const UPLOAD_MAXIMUM_FIVE_FILES_ENG = 'Upload a maximum of 5 documents of 10MB each';
export const UPLOAD_MAXIMUM_FIVE_FILES_ESP =
  'Cargar hasta un máximo de 5 documentos de 10MB cada uno';
export const UNDO_REMOVED_FILE_ENG = 'Undo Removed File';
export const UNDO_REMOVED_FILE_ESP = 'Recuperar el documento borrado';
export const SUPPORTED_FILE_TYPE_ENG = 'Supported file type';
export const SUPPORTED_FILE_TYPE_ESP = 'Tipo de archivo compatible';
export const UPLOADED_MAX_SIZE_ENG = 'Uploaded file exceeds the max size';
export const UPLOADED_MAX_SIZE_ESP = 'El documento cargado excede el tamaño máximo';
export const PLEASE_WAIT_ENG = 'Please, Wait while we process your request.';
export const PLEASE_WAIT_ESP = 'Por favor, espere mientras procesamos su solicitud.';
