import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const Dropdown = ({ countriesList }) => {
  const { t } = useTranslation();
  return (
    <select className="custom-select">
      <option key="'-- Select --'" value="'-- Select --'">
        {t('-- Select --', {
          defaultValue: '-- Select --',
        })}
      </option>
      {countriesList.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};
Dropdown.propTypes = {
  countriesList: PropTypes.array,
};
export default Dropdown;
