/* eslint-disable no-await-in-loop */
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toDataURL } from 'qrcode';
import { Buffer } from 'buffer';
import SparkMD5 from 'spark-md5';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import GenerateLexUserCookie from '../connectivity/GenerateLexUserCookie';
import GenerateLexUserId from '../connectivity/GenerateLexUserId';
import { ADDITIONAL_DETAILS, CUSTOMER_MESSAGE, MESSAGE_BODY } from '../Constants';

const lexUserId = GenerateLexUserId();
const lexUserCookie = GenerateLexUserCookie();
export const base64Authorization = Buffer.from(
  `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
).toString('base64');
export const getParameters = (
  message,
  slotToElicit,
  englishBot,
  sessionID,
  chatDeviceType,
  chatDeviceOS,
  SspUserLogin,
  uuid
) => {
  let inputMessage = '';
  if (
    slotToElicit === ADDITIONAL_DETAILS ||
    slotToElicit === MESSAGE_BODY ||
    slotToElicit === CUSTOMER_MESSAGE
  ) {
    inputMessage = `<slot>${message}<slot>`;
  } else {
    inputMessage = message;
  }
  const requestAttributes = {
    userId: lexUserId,
    deviceType: chatDeviceType,
    DeviceOS: chatDeviceOS,
    isLoggedIn: SspUserLogin === 'true' ? 'Y' : 'N',
    chatbotktc: lexUserCookie,
  };

  if (uuid && uuid.length > 0) {
    requestAttributes.DocumentsUUID = uuid.join(',');
  }

  const obj = {
    botAliasId: process.env.REACT_APP_BOT_ALIAS_ID,
    botId: process.env.REACT_APP_BOT_ID,
    inputText: inputMessage,
    locale: englishBot ? 'en_US' : 'es_US',
    sessionId: sessionID || '',
    requestAttributes,
  };

  return obj;
};

export const downloadAttachmentFile = async (id, token) => {
  const data = {
    attachmentId: id,
    connectionToken: token,
  };
  const response = await axios.post(
    process.env.REACT_APP_TOKEN_SERVICE_URL,
    {
      clientId: process.env.REACT_APP_CLIENT_ID,
    },
    {
      headers: {
        Authorization: `Basic ${base64Authorization}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  const innerResponse = await axios.post(process.env.REACT_APP_DOWNLOAD_FILE_CALL_API, data, {
    headers: {
      Authorization: `Bearer ${response.data.access_token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return innerResponse.data.presignedURL;
};

export const timeConvert = (num) => {
  let hours = Math.floor(num / 60);
  let minutes = num % 60;
  minutes = String(minutes).padStart(2, '0');
  hours = String(hours).padStart(2, '0');
  return `${hours}:${minutes}`;
};
export const disableAllChatButtons = () => {
  const elems = document.getElementsByClassName('quick-reply-button');
  if (elems && elems.length > 0) {
    for (const element of elems) {
      element.disabled = true;
    }
  }
};

export const toggleChatbotContainerSize = (popupRunningFlag) => {
  if (!popupRunningFlag) {
    if ('parentIFrame' in window) {
      window.parentIFrame.getPageInfo((obj) => {
        const winHeight = (obj.windowHeight * 95) / 100;
        if (isMobile) {
          window.parentIFrame.size(winHeight, obj.windowWidth);
        } else {
          window.parentIFrame.size(winHeight, 470);
        }
      });
    }
  }
};

export const deleteSessioApiCall = (englishBot, sessionID) => {
  axios
    .post(
      process.env.REACT_APP_TOKEN_SERVICE_URL,
      {
        clientId: process.env.REACT_APP_CLIENT_ID,
      },
      {
        headers: {
          Authorization: `Basic ${base64Authorization}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then((response) => {
      axios.post(
        `${process.env.REACT_APP_DELETE_SESSION_API}`,
        {
          botAliasId: process.env.REACT_APP_BOT_ALIAS_ID,
          botId: process.env.REACT_APP_BOT_ID,
          locale: englishBot ? 'en_US' : 'es_US',
          sessionId: sessionID,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
    })
    .catch();
};
export const focusInChatbot = () => {
  const messageGroup = document.getElementById('messageGroup');
  const messageTextarea = document.getElementById('messageText');
  if (
    messageGroup &&
    messageGroup.lastChild.lastElementChild.querySelectorAll('.quick-replies').length > 0
  ) {
    messageGroup.lastChild.lastElementChild
      .querySelectorAll('.quick-replies')[0]
      .firstChild.focus();
  } else if (messageTextarea) {
    messageTextarea.focus();
  }
};

export const readFileAsDataURL = (file) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      const base64String = event.target.result.split(',')[1];
      resolve(base64String);
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsDataURL(file);
  });
};

export const getbase64fromFile = async (fileArr) => {
  if (fileArr) {
    const base64Result = [];
    fileArr.forEach(async (file) => {
      const base64String = await readFileAsDataURL(file);
      base64Result.push(base64String);
    });
    return base64Result;
  }
  return [];
};

export const generateuuid = () => uuidv4();

export const postAttachments = async (sessionID, uuid, formData, accessToken, fileData) => {
  const response = await axios
    .post(
      `${process.env.REACT_APP_FILE_UPLOAD_LESS_THAN_4_MB}/?uuid=${uuid}&eventName=${fileData.eventName}&sessionId=${sessionID}&size=${fileData.size}&source=${fileData.source}&format=${fileData.format}&fileName=${fileData.fileName}&language=${fileData.lang}&info=${fileData.info}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(() => true)
    .catch(() => false);
  return response;
};

export const generateFileData = (file) =>
  new Promise((resolve, reject) => {
    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
    const chunkSize = 2097152;
    const chunks = Math.ceil(file.size / chunkSize);

    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      spark.append(e.target.result);
      currentChunk += 1;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        const hash = spark.end();
        resolve(hash);
      }
    };

    fileReader.onerror = () => {
      reject();
    };
    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    loadNext();
  });

export const uploadDocumentByPreSignedURL = async (
  preSignedUrl,
  file,
  fileMd5,
  callbackFunction,
  args
) => {
  let response = null;
  try {
    response = await axios
      .put(preSignedUrl, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-MD5': fileMd5,
        },
      })
      .then(() => {
        if (args && callbackFunction) {
          const { payload, customerVerifyToken, lang, generateKey, isFileSizeGreaterThan4MB } =
            args;
          return callbackFunction(
            payload,
            customerVerifyToken,
            lang,
            generateKey,
            isFileSizeGreaterThan4MB
          );
        }
      });
  } catch (e) {
    return response;
  }
  return response;
};

export const postFileUpload = async (
  data,
  uuid,
  token,
  sessionId,
  eventName,
  callbackFunction = false,
  args = false
) => {
  const md5base64 = Buffer.from(data?.contentMD5, 'hex').toString('base64');
  const body = {
    uuid,
    source: 'Chatbot',
    sessionId,
    eventName,
    fileName: data.fileName,
    format: 'pdf',
    size: data.fileSize,
    info: 'File',
    contentMD5: md5base64,
  };
  let response = null;
  try {
    response = await axios
      .post(`${process.env.REACT_APP_FILE_UPLOAD}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      // eslint-disable-next-line arrow-body-style
      .then((res) => {
        return uploadDocumentByPreSignedURL(
          res?.data?.preSignedUrl,
          data.file,
          md5base64,
          callbackFunction,
          args
        );
      });
  } catch (e) {
    return e.response;
  }
  return response;
};

export const getQRCode = (deviceType) => {
  let qrValue;
  let inputURL;
  if (deviceType === 'DEVICE_TYPE_IPHONE') {
    inputURL = 'https://itunes.apple.com/us/app/ath-m%C3%B3vil/id658539297?mt=8';
  } else {
    inputURL = 'https://play.google.com/store/apps/details?id=com.evertec.athmovil.android&hl=en';
  }
  toDataURL(
    inputURL,
    {
      width: 250,
      margin: 2,
    },
    (err, url) => {
      if (err) {
        console.error(err);
        return;
      }
      qrValue = url;
    }
  );
  return qrValue;
};
export const getCountriesList = async (englishBot, handleCountriesList) => {
  let resp = null;
  try {
    resp = await axios
      .post(
        process.env.REACT_APP_TOKEN_SERVICE_URL,
        {
          clientId: process.env.REACT_APP_CLIENT_ID,
        },
        {
          headers: {
            Authorization: `Basic ${base64Authorization}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        axios
          .get(`${process.env.REACT_APP_GET_COUNTRIES_LIST}/${englishBot ? 'en' : 'es'}`, {
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            handleCountriesList(res.data);
          });
      });
  } catch (e) {
    return e.response;
  }
};
