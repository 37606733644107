import { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Button, SvgIcon } from '@material-ui/core';

import styles from './FileUpload.module.css';
import { ReactComponent as closeIcon } from '../images/close.svg';
import { ReactComponent as checkmarkIcon } from '../images/checkmark.svg';
import { ReactComponent as backIcon } from '../images/back.svg';
import { ReactComponent as infoIcon } from '../images/info icon.svg';
import { FileProgressBar } from './FileProgressBar';
import {
  CANCEL_ENG,
  CANCEL_ESP,
  CONFIRM_ENG,
  CONFIRM_ESP,
  PLEASE_UPLOAD_DOCUMENTS_ENG,
  PLEASE_UPLOAD_DOCUMENTS_ESP,
  REMOVE_ALL_ENG,
  REMOVE_ALL_ESP,
  SELECT_FILES_ENG,
  SELECT_FILES_ESP,
  SUPPORTED_FILE_TYPE_ENG,
  SUPPORTED_FILE_TYPE_ESP,
  UNDO_REMOVED_FILES_ENG,
  UNDO_REMOVED_FILES_ESP,
  UNDO_REMOVED_FILE_ENG,
  UNDO_REMOVED_FILE_ESP,
  UPLOADED_MAX_SIZE_ENG,
  UPLOADED_MAX_SIZE_ESP,
  UPLOAD_MAXIMUM_FIVE_FILES_ENG,
  UPLOAD_MAXIMUM_FIVE_FILES_ESP,
} from '../../../Constants';

const FileUpload = ({ uploadDocuments, cancelUploadDocuments, englishBot }) => {
  const wrapperRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showProgress, setShowProgress] = useState([]);
  const [showProgressError, setShowProgressError] = useState({});
  const [removedAll, setRemovedAll] = useState(false);
  const [removeFilesList, setRemoveFilesList] = useState([]);
  const [selectedFileWithRemovedData, setSelectedFileWithRemovedData] = useState({});

  const scrollToBottom = () => {
    const messageContainer = document.getElementById('messageContainer');
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight - messageContainer.clientHeight;
    }
  };

  const onFileSelect = async (e) => {
    const file = e.target.files[0];
    file.identifier = Math.random() + file.name;

    if (file.size > 10 * 1024 * 1024 || file.type !== 'application/pdf') {
      const obj = { ...showProgressError };
      const msg = englishBot ? UPLOADED_MAX_SIZE_ENG : UPLOADED_MAX_SIZE_ESP;
      obj[showProgress.length] = {
        status: true,
        message:
          file.type !== 'application/pdf'
            ? `${englishBot ? SUPPORTED_FILE_TYPE_ENG : SUPPORTED_FILE_TYPE_ESP}: PDF`
            : msg,
      };
      setShowProgressError(obj);

      const progressArr = [...showProgress];
      progressArr.push(true);
      setShowProgress(progressArr);
      const arr = [...selectedFiles];
      arr.push(false);
      setTimeout(() => {
        setSelectedFiles(arr);
        scrollToBottom();
      }, 1000);
    } else {
      const arr = [...selectedFiles];
      arr.push(file);

      const obj = { ...showProgressError };
      obj[showProgress.length] = {
        status: false,
        message: '',
      };
      setShowProgressError(obj);

      const progressArr = [...showProgress];
      progressArr.push(true);
      setShowProgress(progressArr);

      setTimeout(() => {
        setSelectedFiles(arr);
        const newArr = [...progressArr];
        newArr[progressArr.length - 1] = false;
        setShowProgress(newArr);
        scrollToBottom();
      }, 1000);
    }
  };

  const handleRemoveAll = () => {
    const arr = [...selectedFiles];
    setRemoveFilesList(arr);
    setRemovedAll(true);
    scrollToBottom();
  };

  const handleUndoAll = () => {
    const arr = [...selectedFiles];
    setSelectedFiles(arr);
    setRemovedAll(false);
    scrollToBottom();
  };

  const handleSingleFileRemoveUsingIndex = (index) => {
    const arr = [...removeFilesList];
    arr[index] = selectedFiles[index];
    setRemoveFilesList(arr);

    scrollToBottom();
  };

  const handleSingleFileRemove = (index) => {
    const obj = { ...selectedFileWithRemovedData };
    obj[index] = {
      undo: true,
      data: selectedFiles[index],
    };
    setSelectedFileWithRemovedData(obj);
    scrollToBottom();
  };

  const sliceFromArr = (items, index, updateItems) => {
    const arr = [...items];
    arr.splice(index, 1);
    updateItems(arr);
  };

  const handleSinglefileUndoRemove = (e, index) => {
    const arr = [...selectedFiles];
    arr[index] = selectedFileWithRemovedData[index].data;
    setSelectedFiles(arr);

    const obj = { ...selectedFileWithRemovedData };
    obj[index] = {};
    setSelectedFileWithRemovedData(obj);
    scrollToBottom();
    e.stopPropagation();
  };

  const handleSinglefileUndoRemovee = (e, index) => {
    const obj = { ...selectedFileWithRemovedData };
    delete obj[index];
    setSelectedFileWithRemovedData(obj);
    sliceFromArr(showProgress, index, setShowProgress);
    sliceFromArr(selectedFiles, index, setSelectedFiles);
    scrollToBottom();

    e.stopPropagation();
  };

  const handleRemoveAllUndoFiles = (e) => {
    setRemoveFilesList([]);
    setSelectedFiles([]);
    setRemovedAll(false);
    setSelectedFileWithRemovedData({});
    setShowProgressError({});
    setShowProgress([]);
    scrollToBottom();

    e.stopPropagation();
  };

  const fileCurrentRemove = (e, index) => {
    const obj = { ...showProgressError };
    delete obj[index];
    setShowProgressError(obj);

    sliceFromArr(showProgress, index, setShowProgress);
    sliceFromArr(selectedFiles, index, setSelectedFiles);
    scrollToBottom();
  };

  const removedFileNames = removeFilesList.map((file) => file.identifier);
  const filteredFileIndices = selectedFiles
    .map((file, index) => (!removedFileNames.includes(file.identifier) ? index : -1))
    .filter((index) => index !== -1);

  return (
    <div
      ref={wrapperRef}
      data-testid="drag_testId"
      className={`dragFileUpdate ${styles['drop-file-input']}`}
    >
      <div className={styles.fileUploadContainer}>
        <div className={styles.dropFileInputLabel}>
          <div className={styles.dropFileInputLabel_inner}>
            <div className={styles.drag}>
              {englishBot ? PLEASE_UPLOAD_DOCUMENTS_ENG : PLEASE_UPLOAD_DOCUMENTS_ESP}
            </div>
            <Button
              type="submit"
              data-testid="submittype_testid"
              variant="contained"
              id={styles.selectFiles}
              className={showProgress.length > 4 ? styles.disabledFileField : ''}
              disabled={showProgress.length > 4}
            >
              {englishBot ? SELECT_FILES_ENG : SELECT_FILES_ESP}
              <input
                name="dragInput"
                type="file"
                value=""
                accept="application/pdf"
                data-testid="dragInput_testid"
                className={styles.activeButton}
                onChange={onFileSelect}
                aria-label={englishBot ? PLEASE_UPLOAD_DOCUMENTS_ENG : PLEASE_UPLOAD_DOCUMENTS_ESP}
              />
            </Button>
          </div>
        </div>
        <div className={styles.notediv}>
          <div className={styles.validation}>
            <span aria-label="info icon" className={styles.infoIconWrapper}>
              <SvgIcon
                aria-label="info"
                role="graphic"
                titleAccess="info Icon"
                component={infoIcon}
              />
              {englishBot ? UPLOAD_MAXIMUM_FIVE_FILES_ENG : UPLOAD_MAXIMUM_FIVE_FILES_ESP}
            </span>
          </div>
        </div>
      </div>
      {!removedAll &&
        showProgress.map((current, index) =>
          showProgress[index] ? (
            <div className={styles.progressBarWrapper}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                key={current.name}
              >
                <Grid item xs={10}>
                  <FileProgressBar
                    errorMessage={showProgressError[index]?.message || ''}
                    error={showProgressError[index]?.status || false}
                    englishBot={englishBot}
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1} className={styles.progressRemoveWrapper}>
                  <span
                    className={styles.removeButton}
                    tabIndex="0"
                    aria-label={selectedFiles[index]?.name}
                    data-testid="removeCurrentButton_testId"
                    onClick={(e) => fileCurrentRemove(e, index)}
                  >
                    <SvgIcon
                      aria-label="close"
                      role="graphic"
                      titleAccess="close Icon"
                      className={styles.progressCloseSvg}
                      component={closeIcon}
                    />
                  </span>
                </Grid>
              </Grid>
            </div>
          ) : selectedFileWithRemovedData[index]?.undo ? (
            <Grid
              container
              className={styles.undoAllWrapper}
              onClick={(e) => handleSinglefileUndoRemove(e, index)}
            >
              <Grid item xs={1} className={styles.pointer}>
                <SvgIcon
                  aria-label="back"
                  role="graphic"
                  titleAccess="back Icon"
                  className={styles.svg}
                  component={backIcon}
                />
              </Grid>
              <Grid item xs={10} className={styles.pointer}>
                {englishBot ? UNDO_REMOVED_FILE_ENG : UNDO_REMOVED_FILE_ESP}
              </Grid>
              <Grid item xs={1}>
                <span tabIndex="0" data-testid="removeButton_testId" className={styles.pointer}>
                  <SvgIcon
                    aria-label="close"
                    role="graphic"
                    titleAccess="close Icon"
                    className={styles.svg}
                    component={closeIcon}
                    onClick={(e) => handleSinglefileUndoRemovee(e, index)}
                  />
                </span>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              key={current.name}
              className={styles.fileListWrapper}
            >
              <Grid item xs={1}>
                <SvgIcon
                  aria-label="checkmark"
                  role="graphic"
                  titleAccess="Checkmark Icon"
                  className={styles.svg}
                  component={checkmarkIcon}
                />
              </Grid>
              <Grid
                item
                xs={9}
                className={styles['drop-file-preview__item__info']}
                sx={{ padding: '10px' }}
              >
                <div className={styles.fileName}>{selectedFiles[index]?.name}</div>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <span
                  className={styles.removeButton}
                  tabIndex="0"
                  aria-label={selectedFiles[index]?.name}
                  data-testid="removeButton_testId"
                  onClick={() => handleSingleFileRemove(index)}
                >
                  <SvgIcon
                    aria-label="close"
                    role="graphic"
                    titleAccess="close Icon"
                    className={styles.svg}
                    component={closeIcon}
                  />
                </span>
              </Grid>
            </Grid>
          )
        )}
      {removedAll && (
        <>
          <Grid container className={styles.undoAllWrapper} onClick={handleUndoAll}>
            <Grid item xs={1} className={styles.pointer}>
              <SvgIcon
                aria-label="back"
                role="graphic"
                titleAccess="back Icon"
                className={styles.svg}
                component={backIcon}
              />
            </Grid>
            <Grid item xs={9} className={styles.pointer}>
              {englishBot
                ? UNDO_REMOVED_FILES_ENG.replace('{num}', removeFilesList.length)
                : UNDO_REMOVED_FILES_ESP.replace('{num}', removeFilesList.length)}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1}>
              <span tabIndex="0" className={styles.pointer} data-testid="removeButton_testId">
                <SvgIcon
                  aria-label="close"
                  role="graphic"
                  titleAccess="close Icon"
                  className={styles.svg}
                  component={closeIcon}
                  onClick={handleRemoveAllUndoFiles}
                />
              </span>
            </Grid>
          </Grid>
          {filteredFileIndices.map((index) => (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={styles.fileListWrapper}
            >
              <Grid item xs={1}>
                <SvgIcon
                  aria-label="checkmark"
                  role="graphic"
                  titleAccess="Checkmark Icon"
                  className={styles.svg}
                  component={checkmarkIcon}
                />
              </Grid>
              <Grid
                item
                xs={9}
                className={styles['drop-file-preview__item__info']}
                sx={{ padding: '10px' }}
              >
                <div className={styles.fileName}>{selectedFiles[index]?.name}</div>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <span
                  className={styles.removeButton}
                  tabIndex="0"
                  aria-label={selectedFiles[index]?.name}
                  data-testid="removeButton_testId"
                  onClick={() => handleSingleFileRemoveUsingIndex(index)}
                >
                  <SvgIcon
                    aria-label="close"
                    role="graphic"
                    titleAccess="close Icon"
                    className={styles.svg}
                    component={closeIcon}
                  />
                </span>
              </Grid>
            </Grid>
          ))}
          {showProgress.map(
            (current, index) =>
              showProgress[index] && (
                <div className={styles.progressBarWrapper}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    key={current.name}
                  >
                    <Grid item xs={10}>
                      <FileProgressBar
                        errorMessage={showProgressError[index]?.message || ''}
                        error={showProgressError[index]?.status || false}
                        englishBot={englishBot}
                      />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={1} className={styles.progressRemoveWrapper}>
                      <span
                        className={styles.removeButton}
                        tabIndex="0"
                        aria-label={selectedFiles[index]?.name}
                        data-testid="removeCurrentButton_testId"
                        onClick={(e) => fileCurrentRemove(e, index)}
                      >
                        <SvgIcon
                          aria-label="close"
                          role="graphic"
                          titleAccess="close Icon"
                          className={styles.progressCloseSvg}
                          component={closeIcon}
                        />
                      </span>
                    </Grid>
                  </Grid>
                </div>
              )
          )}
        </>
      )}
      {selectedFiles.length > 0 && (
        <div className={styles.buttonsWrapper}>
          <Button
            className={`${styles.actionButtons}`}
            variant="outlined"
            type="button"
            onClick={() => cancelUploadDocuments()}
          >
            {englishBot ? CANCEL_ENG : CANCEL_ESP}
          </Button>
          <Button
            className={`${styles.actionButtons}`}
            variant="outlined"
            type="button"
            onClick={handleRemoveAll}
          >
            {englishBot ? REMOVE_ALL_ENG : REMOVE_ALL_ESP}
          </Button>
          <Button
            className={`${styles.containedActionButton}`}
            variant="contained"
            type="button"
            onClick={() =>
              uploadDocuments(
                selectedFiles,
                selectedFileWithRemovedData,
                removedAll,
                removeFilesList
              )
            }
          >
            {englishBot ? CONFIRM_ENG : CONFIRM_ESP}
          </Button>
        </div>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  uploadDocuments: PropTypes.func,
  cancelUploadDocuments: PropTypes.func,
  englishBot: PropTypes.bool,
};

export default FileUpload;
