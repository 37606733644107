import { Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { CommonStyles } from '../CommonStyles';

const useStyles = CommonStyles();

const SelectInput = (props) => {
  const { t } = useTranslation();
  const { englishBot, menuLabel, menuObject, inputHandleChange } = props;
  const CommonCssStyles = useStyles();
  const formikContext = useFormikContext();
  const { values, errors, touched, handleBlur } = formikContext;
  const outlinedInputsError = {
    background: '#FD00000D',
  };
  return (
    <Grid item container xs={12} className={CommonCssStyles.fields_grid}>
      <Grid container item xs={12} md={12}>
        <Grid
          item
          xs={12}
          md={12}
          className={
            (errors.accountType && touched.accountType) ||
            (errors.accountType && values.accountType !== '')
              ? CommonCssStyles.ErrorEnableGrid
              : {}
          }
        >
          <InputLabel
            htmlFor="accountType"
            required
            // aria-label={`${t('Now, let us know your email to send you the document', { defaultValue: "Now, let us know your email to send you the document" })}: ${t('Email Address', { defaultValue: "Email Address" })} ${t('Required', { defaultValue: "Required" })}`}
            className={CommonCssStyles.label_title}
          >
            {menuLabel}:
          </InputLabel>
          {/* <OutlinedInput fullWidth className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}> */}
          <Select
            id="accountType"
            name="accountType"
            variant="outlined"
            role="listbox"
            value={values.accountType}
            onBlur={handleBlur}
            data-testid="acctTypetest"
            onChange={(e) => inputHandleChange(e)}
            autoComplete="off"
            fullWidth
            className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
            style={
              (errors.accountType && touched.accountType) ||
              (errors.accountType && values.accountType !== '')
                ? outlinedInputsError
                : {}
            }
            displayEmpty
            // inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="">
              <em>{englishBot ? 'None' : 'Ninguno'}</em>
            </MenuItem>
            {menuObject.map((item) => (
              <MenuItem key={item.id} value={item.value}>
                {englishBot ? item.labelEng : item.labelEsp}
              </MenuItem>
            ))}
          </Select>
          {/* </OutlinedInput> */}
          <span className={CommonCssStyles.error_message_input}>
            {((errors.accountType && touched.accountType) ||
              (errors.accountType && values.accountType !== '')) && (
              <span role="alert" tabIndex="-1">
                {t(errors.accountType, { defaultValue: errors.accountType })}
              </span>
            )}
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
};
SelectInput.propTypes = {
  englishBot: PropTypes.bool,
  menuLabel: PropTypes.string,
  menuObject: PropTypes.array,
  inputHandleChange: PropTypes.func,
};

export default SelectInput;
