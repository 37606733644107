import { useEffect } from 'react';
import { IconButton } from '@livechat/ui-kit';
import { isMobile, isIOS } from 'react-device-detect';
import { PropTypes } from 'prop-types';
import widgetIcon from './images/widgetIcon.png';
import { CommonStyles } from './balance-cancellation/CommonStyles';
import { bankDomains } from './utils/CommonData';

const useCommonStyles = CommonStyles();

const Minimized = ({ maximize, btnIntentTrigger, handlerIntentTrigger }) => {
  const CommoncssStyles = useCommonStyles();
  useEffect(() => {
    if (btnIntentTrigger !== '') {
      setTimeout(() => {
        if ('parentIFrame' in window) {
          for (const target of bankDomains) {
            window.parent.postMessage('disable loader', target);
          }
        }
        chatIconClicked();
        handlerIntentTrigger();
      }, 1000);
    }
  }, [btnIntentTrigger]);
  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  };
  const chatIconClicked = () => {
    if (typeof window !== 'undefined') {
      if (window.parentIFrame) {
        window.parentIFrame.getPageInfo((obj) => {
          const winHeight = (obj.windowHeight * 99) / 100;
          if (getDeviceType() === 'mobile') {
            window.parentIFrame.size(winHeight, obj.windowWidth);
          } else {
            window.parentIFrame.size(winHeight, 470);
          }
          if (!isMobile && !isIOS) {
            document
              .getElementsByClassName('main-wrapper')[0]
              .setAttribute('style', 'width: 95vw !important; height: 94vh !important;');
            document.getElementsByClassName('main-wrapper')[0].style.width = '95vw !important';
            document
              .getElementsByClassName('main-container')[0]
              .setAttribute('style', 'width: 95vw !important; height: 94vh !important;');
            document.getElementsByClassName('main-container')[0].style.width = '95vw !important';
            document.getElementsByClassName('main-wrapper')[0].style.height = '95vh !important';
            document.getElementsByClassName('main-container')[0].style.height = '95vh !important';
          }
        });
      }
    }
    maximize();
  };
  return (
    <IconButton
      onClick={chatIconClicked}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        padding: '0',
      }}
      className={CommoncssStyles.minimized}
      data-testid="chatIcon_testid"
    >
      {!btnIntentTrigger && <img src={widgetIcon} alt="chatbot" tabIndex="0" />}
    </IconButton>
  );
};
export default Minimized;

Minimized.propTypes = {
  btnIntentTrigger: PropTypes.string,
  maximize: PropTypes.func,
  handlerIntentTrigger: PropTypes.func,
};
