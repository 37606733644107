import { useEffect, useState } from 'react';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { PLEASE_WAIT_ENG, PLEASE_WAIT_ESP } from '../../../Constants';

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: 'white',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#F5821F',
    },
    '& .progressTitle': {
      color: '#E37617',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '10px',
      lineHeight: '150%',
      paddingTop: '8px',
    },
  },
}));

export const useStylesError = makeStyles(() => ({
  root: {
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: 'white',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#D71721',
    },
    '& .progressTitle': {
      color: '#E37617',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '10px',
      lineHeight: '150%',
      paddingTop: '8px',
    },
  },
}));

export const FileProgressBar = ({ error, errorMessage, englishBot }) => {
  const [progress, setProgress] = useState(0);
  const classes = useStyles();
  const classesError = useStylesError();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 100));
    }, 500);

    setTimeout(() => {
      clearInterval(timer);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const msg = englishBot ? PLEASE_WAIT_ENG : PLEASE_WAIT_ESP;

  const message = error ? errorMessage : msg;

  return (
    <div className={error ? classesError.root : classes.root}>
      <LinearProgress variant="determinate" value={progress} />
      <div className="progressTitle">{progress ? <div>{message}</div> : ''}</div>
    </div>
  );
};

FileProgressBar.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  englishBot: PropTypes.bool,
};
