import React from 'react';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import { validationSchemaForServiceToMyAccount } from '../balance-cancellation/FormModel/validationSchema';
import { formInitialValuesForServiceToMyAccount } from '../balance-cancellation/FormModel/formInitialValues';
import { Formik, Form } from 'formik';
import { CommonStyles } from '../balance-cancellation/CommonStyles';
import ServiceAccountForm from './ServiceAccountForm';
import { base64Authorization } from '../utils/CommonFunctions';
import { PropTypes } from 'prop-types';
const useCommonStyles = CommonStyles();
const ServiceToMyAccount = (props) => {
  const CommonCssStyles = useCommonStyles();
  const {
    englishBot,
    sessionID,
    setServiceFormTrigger,
    setGeneralFormTrigger,
    formInProcessTrigger,
    handeButtonLinkMessage,
    setCustomerDetails,
    spinnerHandler,
    setShowRegisterCustomerButton,
    liveAgentJoined,
    sendToConnect,
  } = props;
  function _handleSubmit(values, actions) {
    setServiceFormTrigger(false);
    formInProcessTrigger(false);
    setShowRegisterCustomerButton(false);
    if (liveAgentJoined) {
      let cusDetails = `customerName: ${values.firstName} ${values.lastName}\n customerEmail: ${values.personEmail}\n customerMobile: ${values.mobileNumber}\n customerDob: ${values.dob_date}\n customerSSN: ${values.socialSecurityNumber}`;
      cusDetails += values.accountNumber ? `\n customerAccountNumber: ${values.accountNumber}` : '';
      sendToConnect(cusDetails);
    } else {
      const params = {
        sessionId: sessionID,
        customerEmail: values.personEmail,
        mobileNumber: `${values.mobileNumber}`,
        customerName: `${values.firstName} ${values.lastName}`,
        accountNumber: values.accountNumber,
        dob: values.dob_date,
        ssn: values.socialSecurityNumber,
        lineOfBusiness: values.accountType,
      };
      axios
        .post(
          process.env.REACT_APP_TOKEN_SERVICE_URL,
          {
            clientId: process.env.REACT_APP_CLIENT_ID,
          },
          {
            headers: {
              Authorization: `Basic ${base64Authorization}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
        .then((response) => {
          axios
            .post(`${process.env.REACT_APP_USER_FORM_URL}`, params, {
              headers: {
                Authorization: `Bearer ${response.data.access_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            })
            .then((res) => {
              const { data } = res;
              if (data.messages && data.messages[0].content.includes('templateType')) {
                const listPickerData = JSON.parse(data.messages[0].content);
                setCustomerDetails(listPickerData.data.content.sessionAttributes);
                setTimeout(() => {
                  spinnerHandler(false);
                  handeButtonLinkMessage(
                    { text: listPickerData.data.content.title, value: '', isButtonMessage: false },
                    false
                  );
                }, 1000);
              }
            })
            .catch((err) => {
              spinnerHandler(false);
            });
        })
        .catch();
    }
  }
  return (
    <Formik
      initialValues={formInitialValuesForServiceToMyAccount}
      validationSchema={validationSchemaForServiceToMyAccount}
      onSubmit={_handleSubmit}
      data-testid="submit_testid"
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Grid item xs={12} className={CommonCssStyles.steps_grid} style={{ margin: '10px' }}>
            <ServiceAccountForm
              englishBot={englishBot}
              setServiceFormTrigger={setServiceFormTrigger}
              setGeneralFormTrigger={setGeneralFormTrigger}
              spinnerHandler={spinnerHandler}
              liveAgentJoined={liveAgentJoined}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
ServiceToMyAccount.propTypes = {
  englishBot: PropTypes.bool,
  sessionID: PropTypes.string,
  setServiceFormTrigger: PropTypes.func,
  setGeneralFormTrigger: PropTypes.func,
  formInProcessTrigger: PropTypes.func,
  handeButtonLinkMessage: PropTypes.func,
  setCustomerDetails: PropTypes.object,
  spinnerHandler: PropTypes.func,
  setShowRegisterCustomerButton: PropTypes.func,
  liveAgentJoined: PropTypes.bool,
  sendToConnect: PropTypes.func,
};
export default ServiceToMyAccount;
