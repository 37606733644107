import React, { useState } from 'react';
import { Grid, InputLabel, OutlinedInput, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';
import { IconButton } from '@livechat/ui-kit';
import { isMobile, isTablet, mobileVendor } from 'react-device-detect';
import { PropTypes } from 'prop-types';
import SSNMask from '../balance-cancellation/FormFields/SSNMask';
import DateInput from '../balance-cancellation/FormFields/DateInput';
import SelectInput from '../balance-cancellation/FormFields/SelectInput';
import { CommonStyles } from '../balance-cancellation/CommonStyles';
import { VerifyFormCss } from './VerifyFormCss';
import { formatPhoneNumber, isValidDate } from '../utils/CommonFunctions';
import { commonFunctionEngBot } from './CommonFunction';
import {
  ACCOUNT_NUMBER_ENG,
  ACCOUNT_NUMBER_ESP,
  ACCOUNT_TYPE_ENG,
  ACCOUNT_TYPE_ESP,
  ALT_MOBILE_NUMBER,
  ALT_MOBILE_NUM_AREA_CODE,
  BACK_ENG,
  BACK_ESP,
  CONSENT_FOR_CALLS_ENG,
  CONSENT_FOR_CALLS_ESP,
  CONTINUE_AS_GUEST_ENG,
  CONTINUE_AS_GUEST_ESP,
  DD,
  EMAIL_ADDRESS,
  ENTER_ACCOUNT_NUMBER_ENG,
  ENTER_EMAIL_ADDRESS,
  ENTER_FIRST_NAME,
  ENTER_LAST_NAME,
  ENTER_MOBILE_NUMBER,
  FIRST_NAME,
  LAST_NAME,
  LET_US_KNOW_EMAIL,
  MMM,
  MOBILE,
  MOBILE_NUMBER,
  MOBILE_NUMBER_CAPS,
  MORE_ABOUT_YOU_ENG,
  MORE_ABOUT_YOU_ESP,
  OTHER_ALT_MOBILE_NUMBER,
  OTHER_MOBILE_NUMBER,
  PROTECT_YOUR_INFO_ENG,
  PROTECT_YOUR_INFO_ESP,
  REQUIRED,
  SEND_ENG,
  SEND_ESP,
  SHARE_CONTACT_INFO_ENG,
  SHARE_CONTACT_INFO_ESP,
  TELL_US_ABOUT_YOURSELF,
  YYYY,
} from '../../../Constants';

const checkMobileLen = (isMobile1, mobileVendor1) => {
  if (isMobile1 && mobileVendor1 === 'Apple') return 14;
  else return 10;
};
const CheckMobileMode = (isMobiledata, isTabletdata) => {
  if (isTabletdata || isMobiledata) return 'numeric';
  else return 'text';
};
const CheckMobileModeEmail = (isMobiledata1, isTabletdata1) => {
  if (isTabletdata1 || isMobiledata1) return 'email';
  else return 'text';
};

const checkMobileCondition = (errors, touched, values) => {
  return (
    (errors.mobileNumber && touched.mobileNumber) ||
    (errors.mobileNumber && values.mobileNumber !== '')
  );
};
const checkMobileReturn = (errors, touched, values, data) => {
  if (
    (errors.mobileNumber && touched.mobileNumber) ||
    (errors.mobileNumber && values.mobileNumber !== '')
  ) {
    return data;
  } else return {};
};

const returnClass = (errors, touched, values, data) => {
  if ((errors && touched) || (errors && values !== '')) return data;
  else return {};
};
const returnClassSpan = (errors, touched, values) => {
  return (errors && touched) || (errors && values !== '');
};
const returnConditionalClass = (errors, touched, data) => {
  if (errors.socialSecurityNumber && touched.socialSecurityNumber) return data;
  else return {};
};

const useVerifyFormCss = VerifyFormCss();
const useCommonStyles = CommonStyles();
const ServiceAccountForm = (props) => {
  const CommonCssStyles = useCommonStyles();
  const VerifyFormCssStyles = useVerifyFormCss();
  const { englishBot, setServiceFormTrigger, setGeneralFormTrigger, spinnerHandler } = props;
  const { t } = useTranslation();
  const [DOBInvalid, setDOBInvalid] = useState(false);
  const [dateInvalid, setDateInvalid] = useState(false);
  const [open, setOpen] = useState(false);
  const todayDate = new Date();
  const formikContext = useFormikContext();

  const { values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting } =
    formikContext;
  const menuLabel = commonFunctionEngBot(englishBot, ACCOUNT_TYPE_ENG, ACCOUNT_TYPE_ESP);

  const menuObject = [
    { labelEng: 'Mortgage', labelEsp: 'Hipoteca', value: 'mortgage' },
    { labelEng: 'Other Accounts', labelEsp: 'Otras cuentas', value: 'other account' },
  ];
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const backToServiceHandler = () => {
    spinnerHandler(false);
    setServiceFormTrigger(false);
    setGeneralFormTrigger(false);
  };

  const handleDateOfBirthChange = (input, value, formikContextValues) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let dateArray = null;
    let formattedDate = null;
    if (input === 'dob_date') {
      formattedDate = format(value, 'dd MMM yyyy');
      dateArray = formattedDate.split(' ');
      const selectedDate = new Date(
        parseInt(dateArray[2], 10) + 18,
        months.indexOf(dateArray[1]),
        parseInt(dateArray[0], 10)
      ).setHours(0, 0, 0, 0);
      if (isValidDate(dateArray[2], dateArray[1], dateArray[0])) {
        setDOBInvalid(false);
        setDateInvalid(false);
        formikContextValues.setFieldValue(
          'dob_date',
          `${dateArray[0]} ${dateArray[1]} ${dateArray[2]}`
        );
        formikContextValues.setFieldValue('dob_day', dateArray[0]);
        formikContextValues.setFieldValue('dob_month', dateArray[1]);
        formikContextValues.setFieldValue('dob_year', dateArray[2]);
        if (selectedDate < todayDate) {
          setDateInvalid(false);
          setDOBInvalid(false);
        } else {
          setDOBInvalid(true);
          setDateInvalid(false);
        }
      }
      setTimeout(() => {
        formikContext.setFieldTouched('dob_date', true);
      }, 100);
    }
  };
  const handleChangeDOBDay = (e, months) => {
    if (e.target.value !== DD && values.dob_month !== MMM && values.dob_year !== YYYY) {
      setDOBInvalid(false);

      setDateInvalid(false);
      const selectedDate = new Date(
        parseInt(values.dob_year, 10) + 18,
        months.indexOf(values.dob_month),
        parseInt(e.target.value, 10)
      ).setHours(0, 0, 0, 0);
      if (
        isValidDate(values.dob_year, values.dob_month, e.target.value) &&
        selectedDate < todayDate
      ) {
        setFieldValue('dob_date', `${e.target.value} ${values.dob_month} ${values.dob_year}`);
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', true);
        }, 100);
      } else {
        setFieldValue('dob_date', `${values.dob_day} ${values.dob_month} ${e.target.value}`);
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', true);
        }, 100);
        if (selectedDate < todayDate) {
          setDateInvalid(true);
          setDOBInvalid(false);
        } else {
          setDOBInvalid(true);
          setDateInvalid(false);
        }
      }
    } else {
      setFieldValue('dob_date', '');
      setTimeout(() => {
        formikContext.setFieldTouched('dob_date', false);
      }, 100);
      setDOBInvalid(false);
      setDateInvalid(false);
    }
  };
  const handleChangeDOBMonth = (e, months) => {
    if (values.dob_day !== DD && e.target.value !== MMM && values.dob_year !== YYYY) {
      setDateInvalid(false);
      setDOBInvalid(false);
      const selectedDate = new Date(
        parseInt(values.dob_year, 10) + 18,
        months.indexOf(e.target.value),
        parseInt(values.dob_day, 10)
      ).setHours(0, 0, 0, 0);
      if (
        isValidDate(values.dob_year, e.target.value, values.dob_day) &&
        selectedDate < todayDate
      ) {
        setFieldValue('dob_date', `${values.dob_day} ${e.target.value} ${values.dob_year}`);
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', true);
        }, 100);
      } else {
        setFieldValue('dob_date', `${values.dob_day} ${values.dob_month} ${e.target.value}`);
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', true);
        }, 100);
        if (selectedDate < todayDate) {
          setDateInvalid(true);
          setDOBInvalid(false);
        } else {
          setDOBInvalid(true);
          setDateInvalid(false);
        }
      }
    } else {
      setFieldValue('dob_date', '');
      setTimeout(() => {
        formikContext.setFieldTouched('dob_date', false);
      }, 100);
      setDOBInvalid(false);
      setDateInvalid(false);
    }
  };
  const handleChangeDOBYear = (e, months) => {
    if (values.dob_day !== DD && values.dob_month !== MMM && e.target.value !== YYYY) {
      setDateInvalid(false);
      setDOBInvalid(false);
      const selectedDate = new Date(
        parseInt(e.target.value, 10) + 18,
        months.indexOf(values.dob_month),
        parseInt(values.dob_day, 10)
      ).setHours(0, 0, 0, 0);
      if (
        isValidDate(e.target.value, values.dob_month, values.dob_day) &&
        selectedDate < todayDate
      ) {
        setFieldValue('dob_date', `${values.dob_day} ${values.dob_month} ${e.target.value}`);
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', true);
        }, 100);
      } else {
        setFieldValue('dob_date', `${values.dob_day} ${values.dob_month} ${e.target.value}`);
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', true);
        }, 100);
        if (selectedDate < todayDate) {
          setDateInvalid(true);
          setDOBInvalid(false);
        } else {
          setDOBInvalid(true);
          setDateInvalid(false);
        }
      }
    } else {
      setFieldValue('dob_date', '');
      setTimeout(() => {
        formikContext.setFieldTouched('dob_date', false);
      }, 100);
      setDOBInvalid(false);
      setDateInvalid(false);
    }
  };
  const handleDateOfBirthChangeDropdown = (e) => {
    handleChange(e);
    setTimeout(() => {
      if (!formikContext.touched[e.target.name]) {
        formikContext.setFieldTouched([e.target.name], true);
        document.getElementById(e.target.name).focus();
      }
    }, 100);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    switch (e.target.name) {
      case 'dob_day':
        handleChangeDOBDay(e, months);
        break;
      case 'dob_month':
        handleChangeDOBMonth(e, months);
        break;
      case 'dob_year':
        handleChangeDOBYear(e, months);
        break;

      default:
    }
  };
  const inputHandleChange = (e) => {
    handleChange(e);
    setTimeout(() => {
      if (!formikContext.touched[e.target.name]) {
        formikContext.setFieldTouched([e.target.name], true);
        if (document.getElementById(e.target.name)) {
          document.getElementById(e.target.name).focus();
        }
      }
    }, 100);
    if (
      e.target.name === MOBILE_NUMBER ||
      e.target.name === ALT_MOBILE_NUMBER ||
      e.target.name === OTHER_MOBILE_NUMBER ||
      e.target.name === OTHER_ALT_MOBILE_NUMBER
    ) {
      if (e.target.value.match(/^\d{10}$/)) {
        formikContext.setFieldValue(e.target.name, formatPhoneNumber(e.target.value));
      } else {
        const value = e.target.value.replace(/[-() ]/g, '');
        formikContext.setFieldValue(e.target.name, value);
      }
      setTimeout(() => {
        formikContext.setFieldTouched([e.target.name], true);
      }, 100);
    }
    if (
      e.target.name === ALT_MOBILE_NUM_AREA_CODE &&
      e.target.value === '' &&
      values.altMobileNumber !== ''
    ) {
      formikContext.setFieldTouched([e.target.name], true);
    }
  };
  const continueAsGuestHandler = () => {
    setServiceFormTrigger(false);
    setGeneralFormTrigger(true);
  };
  const outlined_inputs_error = {
    background: '#FD00000D',
  };
  const sendOpacity = {
    opacity: '0.5',
    pointerEvents: 'none',
  };
  const sendOpacityFull = {
    opacity: '1',
  };

  return (
    <div
      id="customerCheckIn"
      style={{
        margin: '7.5px',
      }}
    >
      <Grid item xs={12} className={VerifyFormCssStyles.form_heading_wrapper}>
        <Typography
          variant="div"
          id="dialog1Title"
          className={VerifyFormCssStyles.form_heading_customerCheckIn}
        >
          <div className={CommonCssStyles.service_acc_margin}>
            {commonFunctionEngBot(englishBot, PROTECT_YOUR_INFO_ENG, PROTECT_YOUR_INFO_ESP)}

            <br />
          </div>
          <div className={CommonCssStyles.service_acc_margin}>
            <span className={CommonCssStyles.service_acc_font}>
              {commonFunctionEngBot(englishBot, SHARE_CONTACT_INFO_ENG, SHARE_CONTACT_INFO_ESP)}
            </span>
            {commonFunctionEngBot(englishBot, CONSENT_FOR_CALLS_ENG, CONSENT_FOR_CALLS_ESP)}
          </div>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className={returnClass(
          errors.firstName,
          touched.firstName,
          values.firstName,
          CommonCssStyles.ErrorEnableGrid
        )}
      >
        <InputLabel
          htmlFor="firstName"
          required
          aria-label={`${t(TELL_US_ABOUT_YOURSELF, {
            defaultValue: TELL_US_ABOUT_YOURSELF,
            nsSeparator: '|',
          })} ${t(FIRST_NAME, { defaultValue: FIRST_NAME })} ${t(REQUIRED, {
            defaultValue: REQUIRED,
          })}`}
          className={CommonCssStyles.label_title}
        >
          {t(FIRST_NAME, { defaultValue: FIRST_NAME })}:
        </InputLabel>
        <OutlinedInput
          id="firstName"
          name="firstName"
          className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
          placeholder={t(ENTER_FIRST_NAME, { defaultValue: ENTER_FIRST_NAME })}
          fullWidth
          inputProps={{ maxLength: 10, 'data-testid': 'firstName_id' }}
          value={values.firstName}
          onBlur={handleBlur}
          onChange={inputHandleChange}
          autoComplete="off"
          style={returnClass(
            errors.firstName,
            touched.firstName,
            values.firstName,
            outlined_inputs_error
          )}
        />
        <span className={CommonCssStyles.error_message_input}>
          {returnClassSpan(errors.firstName, touched.firstName, values.firstName) && (
            <span role="alert" tabIndex="-1">
              {t(errors.firstName, { defaultValue: errors.firstName })}
            </span>
          )}
        </span>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        className={returnClass(
          errors.lastName,
          touched.lastName,
          values.lastName,
          CommonCssStyles.ErrorEnableGrid
        )}
      >
        <InputLabel
          htmlFor="lastName"
          required
          aria-label={`${t(LAST_NAME, { defaultValue: LAST_NAME })} ${t(REQUIRED, {
            defaultValue: REQUIRED,
          })}`}
          className={CommonCssStyles.label_title}
        >
          {t(LAST_NAME, { defaultValue: LAST_NAME })}:
        </InputLabel>
        <OutlinedInput
          id="lastName"
          name="lastName"
          className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
          placeholder={t(ENTER_LAST_NAME, { defaultValue: ENTER_LAST_NAME })}
          fullWidth
          inputProps={{ maxLength: 15, 'data-testid': 'lastName_id' }}
          value={values.lastName}
          onBlur={handleBlur}
          onChange={inputHandleChange}
          autoComplete="off"
          style={returnClass(
            errors.lastName,
            touched.lastName,
            values.lastName,
            outlined_inputs_error
          )}
        />
        <span className={CommonCssStyles.error_message_input}>
          {returnClassSpan(errors.lastName, touched.lastName, values.lastName) && (
            <span role="alert" tabIndex="-1">
              {t(errors.lastName, { defaultValue: errors.lastName })}
            </span>
          )}
        </span>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className={returnClass(
          errors.personEmail,
          touched.personEmail,
          values.personEmail,
          CommonCssStyles.ErrorEnableGrid
        )}
      >
        <InputLabel
          htmlFor="personEmail"
          required
          aria-label={`${t(LET_US_KNOW_EMAIL, {
            defaultValue: LET_US_KNOW_EMAIL,
          })}: ${t(EMAIL_ADDRESS, { defaultValue: EMAIL_ADDRESS })} ${t(REQUIRED, {
            defaultValue: REQUIRED,
          })}`}
          className={CommonCssStyles.label_title}
        >
          {t(EMAIL_ADDRESS, { defaultValue: EMAIL_ADDRESS })}:
        </InputLabel>
        <OutlinedInput
          id="personEmail"
          name="personEmail"
          className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
          placeholder={t(ENTER_EMAIL_ADDRESS, { defaultValue: ENTER_EMAIL_ADDRESS })}
          fullWidth
          inputProps={{
            inputMode: CheckMobileModeEmail(isMobile, isTablet),
            'data-testid': 'personEmail_id',
          }}
          value={values.personEmail}
          onBlur={handleBlur}
          onChange={inputHandleChange}
          autoComplete="off"
          style={returnClass(
            errors.personEmail,
            touched.personEmail,
            values.personEmail,
            outlined_inputs_error
          )}
        />
        <span className={CommonCssStyles.error_message_input}>
          {returnClassSpan(errors.personEmail, touched.personEmail, values.personEmail) && (
            <span role="alert" tabIndex="-1">
              {t(errors.personEmail, { defaultValue: errors.personEmail })}
            </span>
          )}
        </span>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className={checkMobileReturn(errors, touched, values, CommonCssStyles.ErrorEnableGrid)}
      >
        <InputLabel
          htmlFor="mobileNumber"
          required
          aria-label={`${t(MOBILE_NUMBER_CAPS, { defaultValue: MOBILE_NUMBER_CAPS })} ${t(
            REQUIRED,
            { defaultValue: REQUIRED }
          )}`}
          className={CommonCssStyles.label_title}
        >
          {t(MOBILE, { defaultValue: MOBILE })}:
        </InputLabel>
        <OutlinedInput
          id="mobileNumber"
          name="mobileNumber"
          className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.mobileFormatStyle}`}
          placeholder={t(ENTER_MOBILE_NUMBER, { defaultValue: ENTER_MOBILE_NUMBER })}
          fullWidth
          autoComplete="off"
          inputProps={{
            maxLength: checkMobileLen(isMobile, mobileVendor),
            inputMode: CheckMobileMode(isMobile, isTablet),
            'data-testid': 'mobileNumber_id',
          }}
          value={values.mobileNumber}
          onBlur={handleBlur}
          onChange={inputHandleChange}
          style={checkMobileReturn(errors, touched, values, outlined_inputs_error)}
        />
        <span className={CommonCssStyles.error_message_input}>
          {checkMobileCondition(errors, touched, values) && (
            <span role="alert" tabIndex="-1">
              {t(errors.mobileNumber, {
                defaultValue: errors.mobileNumber,
              })}
            </span>
          )}
        </span>
      </Grid>
      <Typography variant="div">
        <div className={VerifyFormCssStyles.form_heading_customerCheckIn}>
          <span className={CommonCssStyles.service_acc_font}>
            {commonFunctionEngBot(englishBot, MORE_ABOUT_YOU_ENG, MORE_ABOUT_YOU_ESP)}
          </span>
        </div>
      </Typography>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        className={returnConditionalClass(errors, touched, CommonCssStyles.ErrorEnableGrid)}
      >
        <SSNMask />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DateInput
          open={open}
          englishBot={englishBot}
          customerCheckIn={true}
          handleClose={handleClose}
          dateInvalid={dateInvalid}
          DOBInvalid={DOBInvalid}
          handleDateOfBirthChangeDropdown={handleDateOfBirthChangeDropdown}
          handleClickOpen={handleClickOpen}
          handleDateOfBirthChange={handleDateOfBirthChange}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <SelectInput
          englishBot={englishBot}
          menuLabel={menuLabel}
          menuObject={menuObject}
          inputHandleChange={inputHandleChange}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className={returnClass(
          errors.accountNumber,
          touched.accountNumber,
          values.accountNumber,
          CommonCssStyles.ErrorEnableGrid
        )}
      >
        <InputLabel htmlFor="accountNumber" className={CommonCssStyles.label_title}>
          {commonFunctionEngBot(englishBot, ACCOUNT_NUMBER_ENG, ACCOUNT_NUMBER_ESP)}:
        </InputLabel>
        <OutlinedInput
          id="accountNumber"
          name="accountNumber"
          className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
          placeholder={t(ENTER_ACCOUNT_NUMBER_ENG, { defaultValue: ENTER_ACCOUNT_NUMBER_ENG })}
          fullWidth
          inputProps={{
            inputMode: CheckMobileModeEmail(isMobile, isTablet),
            'data-testid': 'accountNumber_id',
          }}
          value={values.accountNumber}
          onBlur={handleBlur}
          onChange={inputHandleChange}
          autoComplete="off"
          style={returnClass(
            errors.accountNumber,
            touched.accountNumber,
            values.accountNumber,
            outlined_inputs_error
          )}
        />
        <span className={CommonCssStyles.error_message_input}>
          {returnClassSpan(errors.accountNumber, touched.accountNumber, values.accountNumber) && (
            <span role="alert" tabIndex="-1">
              {t(errors.accountNumber, { defaultValue: errors.accountNumber })}
            </span>
          )}
        </span>
      </Grid>

      <div
        className="form-btn"
        style={{
          width: '100%',
          marginTop: '10px',
        }}
      >
        <IconButton
          disabled={isSubmitting}
          className="end-btn"
          style={Object.keys(errors).length === 0 ? sendOpacityFull : sendOpacity}
          data-testid="send_id"
        >
          {commonFunctionEngBot(englishBot, SEND_ENG, SEND_ESP)}
        </IconButton>
        <IconButton
          className="end-btn"
          onClick={continueAsGuestHandler}
          data-testid="continueAsGuestHandler_id"
        >
          {commonFunctionEngBot(englishBot, CONTINUE_AS_GUEST_ENG, CONTINUE_AS_GUEST_ESP)}
        </IconButton>
        <IconButton
          className="end-btn"
          onClick={backToServiceHandler}
          data-testid="backToServiceHandler_testid"
        >
          {commonFunctionEngBot(englishBot, BACK_ENG, BACK_ESP)}
        </IconButton>
      </div>
    </div>
  );
};

ServiceAccountForm.propTypes = {
  englishBot: PropTypes.bool,
  setServiceFormTrigger: PropTypes.func,
  setGeneralFormTrigger: PropTypes.func,
  spinnerHandler: PropTypes.func,
  liveAgentJoined: PropTypes.bool,
};

export default ServiceAccountForm;
