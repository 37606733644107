import { OutlinedInput, InputLabel } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { CommonStyles } from '../CommonStyles';
import { REQUIRED } from '../../../../Constants';

const useStyles = CommonStyles();

export const TextInput = ({ label, ariaLabel, field, form, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formikContext = useFormikContext();
  const { errors, touched } = formikContext;
  const outlinedInputsError = {
    background: '#FD00000D',
  };
  const errorFlag = errors.socialSecurityNumber && touched.socialSecurityNumber;
  return (
    <div>
      {label && (
        <InputLabel
          htmlFor={props.id || props.name}
          className={classes.label_title}
          aria-label={`${t(label, { defaultValue: label })}  ${t(REQUIRED, {
            defaultValue: REQUIRED,
          })}`}
          required={props.labelrequired === 'true'}
        >
          {t(label, { defaultValue: label })}:
          {/* {`${t(label, { defaultValue: label })} ${values.socialSecurityNumber}`}: */}
        </InputLabel>
      )}

      <OutlinedInput
        className={
          props.classNewOveride
            ? `${props.classNewOveride}`
            : `${classes.outlined_inputs} ${classes.input_disabled_style} ${props.classNewAdd}`
        }
        fullWidth
        placeholder={t(props.customplaceholder, {
          defaultValue: props.customplaceholder,
        })}
        autoComplete="off"
        style={
          errors.socialSecurityNumber && touched.socialSecurityNumber ? outlinedInputsError : {}
        }
        {...field}
        {...props}
      />
      {errorFlag && (
        <span className={classes.error_message_input}>
          <span role="alert" tabIndex="-1">
            {t(errors.socialSecurityNumber, { defaultValue: errors.socialSecurityNumber })}
          </span>
        </span>
      )}
    </div>
  );
};
TextInput.propTypes = {
  label: PropTypes.string,
  ariaLabel: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  labelrequired: PropTypes.string,
  classNewOveride: PropTypes.string,
  classNewAdd: PropTypes.string,
  customplaceholder: PropTypes.string,
};
