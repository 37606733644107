import axios from 'axios';
import ReactGA from 'react-ga';
import moment from 'moment';
import { Buffer } from 'buffer';
import { PropTypes } from 'prop-types';
import GenerateLexUserCookie from '../../../connectivity/GenerateLexUserCookie';
import GenerateLexUserId from '../../../connectivity/GenerateLexUserId';
import {
  ACCOUNT_NO,
  ACC_NUM,
  ENG,
  EN_US,
  ESP,
  ES_PR,
  SOCIAL_SECURITY_NUMBER,
  TRAVEL_ACC_NUM,
  HI,
  HOLA,
  MOB_NUM,
  CUSTOMER_MOBILE,
  PRIMARY_PHONE_NUMBER,
  ROUTING_NUM,
  LAST_FOUR_SSN,
  LASTFOUR_SSN,
  LNAMT_TO_PAY,
  CCAMT_TO_PAY,
  CUSTOMER_EMAIL,
  EMAIL,
  MESSAGE_BODY,
  FULL_NAME,
  CUSTOMER_NAME,
  PAYMENT_ACC_NUM,
  SSN_SSN,
} from '../../../Constants';
import { getBotConversation } from './EmailTranscript';

const lexUserId = GenerateLexUserId();
const lexUserCookie = GenerateLexUserCookie();
export const base64Authorization = Buffer.from(
  `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
).toString('base64');
export const focusInChatbot = (messages) => {
  const messageGroup = document.getElementById('messageGroup');
  const messageTextarea = document.getElementById('messageText');
  const messageContainer = document.getElementById('messageContainer');
  if (messages.length > 0 && messageGroup && messageContainer) {
    messageContainer.scrollTop = messageContainer.scrollHeight - messageContainer.clientHeight;
    if (messageGroup.lastChild.lastElementChild.querySelectorAll('.quick-replies').length > 0) {
      messageGroup.lastChild.lastElementChild
        .querySelectorAll('.quick-replies')[0]
        .firstChild.focus();
    } else if (messageGroup.lastChild.lastElementChild.querySelectorAll('.calendar').length > 0) {
      messageGroup.lastChild.lastElementChild.querySelectorAll('.calendar')[0].firstChild.focus();
    } else if (messageTextarea) {
      messageTextarea.focus();
    }
  }
};

focusInChatbot.propTypes = {
  messages: PropTypes.array,
};

export const onLoadFunction = (
  englishBot,
  messagesLength,
  btnIntentTrigger,
  handlerLiveAgentTrigger,
  handleMessage,
  runningAWSConnect
) => {
  ReactGA.initialize('UA-8554016-26');
  ReactGA.event({
    category: 'Chatbot',
    action: 'Opened',
    label: 'Chatbot opened',
  });
  if (messagesLength === 0) {
    if (runningAWSConnect) {
      handlerLiveAgentTrigger();
    }
    if (btnIntentTrigger !== '') {
      handleMessage({ text: btnIntentTrigger, value: btnIntentTrigger }, false);
    } else if (englishBot) {
      handleMessage({ text: HI, value: HI }, false);
    } else {
      handleMessage({ text: HOLA, value: HOLA }, false);
    }
  }
};

onLoadFunction.propTypes = {
  englishBot: PropTypes.bool,
  messagesLength: PropTypes.number,
  btnIntentTrigger: PropTypes.string,
  handlerLiveAgentTrigger: PropTypes.func,
  handleMessage: PropTypes.func,
};

export const setMaxLengthForInputBox = (
  slotToElicit,
  setMaxLength,
  setNumericKeyboard,
  setAutoCompleteFlag
) => {
  if (slotToElicit === LAST_FOUR_SSN || slotToElicit === LASTFOUR_SSN) {
    setMaxLength(4);
  } else if (
    slotToElicit === MOB_NUM ||
    slotToElicit === CUSTOMER_MOBILE ||
    slotToElicit === PRIMARY_PHONE_NUMBER ||
    slotToElicit === ACC_NUM
  ) {
    setMaxLength(10);
  } else if (
    slotToElicit === SOCIAL_SECURITY_NUMBER ||
    slotToElicit === SSN_SSN ||
    slotToElicit === ROUTING_NUM
  ) {
    setMaxLength(9);
  } else if (slotToElicit === TRAVEL_ACC_NUM || slotToElicit === PAYMENT_ACC_NUM) {
    setMaxLength(16);
  } else if (
    slotToElicit === CUSTOMER_EMAIL ||
    slotToElicit === EMAIL ||
    slotToElicit === MESSAGE_BODY ||
    slotToElicit === FULL_NAME ||
    slotToElicit === CUSTOMER_NAME ||
    slotToElicit === ACCOUNT_NO
  ) {
    setAutoCompleteFlag(true);
    setMaxLength(250);
  } else {
    setMaxLength(250);
  }
  if (
    slotToElicit === SOCIAL_SECURITY_NUMBER ||
    slotToElicit === SSN_SSN ||
    slotToElicit === MOB_NUM ||
    slotToElicit === CUSTOMER_MOBILE ||
    slotToElicit === PRIMARY_PHONE_NUMBER ||
    slotToElicit === ACC_NUM ||
    slotToElicit === LAST_FOUR_SSN ||
    slotToElicit === LASTFOUR_SSN ||
    slotToElicit === TRAVEL_ACC_NUM ||
    slotToElicit === PAYMENT_ACC_NUM ||
    slotToElicit === ROUTING_NUM ||
    slotToElicit === LNAMT_TO_PAY ||
    slotToElicit === CCAMT_TO_PAY
  ) {
    setNumericKeyboard(true);
  } else {
    setNumericKeyboard(false);
  }
};

setMaxLengthForInputBox.propTypes = {
  slotToElicit: PropTypes.string,
  setMaxLength: PropTypes.func,
  setNumericKeyboard: PropTypes.bool,
  setAutoCompleteFlag: PropTypes.func,
};
export const isLastMessage = (messages, message) => {
  if (messages[messages.length - 1].message === message) {
    return true;
  }
  if (messages.length > 1) {
    if (messages[messages.length - 2].message === message) {
      return true;
    }
  }
};

isLastMessage.propTypes = {
  messages: PropTypes.array,
  message: PropTypes.object,
};
export const isLastMessageIncludes = (message, messages) => {
  if (messages[messages.length - 1].message.includes(message)) {
    return true;
  }
  if (messages.length > 1) {
    if (messages[messages.length - 2].message.includes(message)) {
      return true;
    }
  }
};

isLastMessageIncludes.propTypes = {
  messages: PropTypes.array,
  message: PropTypes.object,
};
export const getWeekday = (englishBot) => {
  let lang = EN_US;
  if (englishBot === false) {
    lang = ES_PR;
  }
  let currentday = new Date().toLocaleString(lang, { weekday: 'short' });
  currentday = currentday.replace('.', '');
  const cday = currentday.substring(0, 4);
  return cday.charAt(0).toUpperCase() + cday.substring(1);
};

getWeekday.propTypes = {
  englishBot: PropTypes.bool,
};

export const feedbackProcessApiHandler = (
  sessionID,
  feedbackDetails,
  awsConnectAgentName,
  customerDetails,
  userName,
  englishBot,
  messages
) => {
  const details = feedbackDetails.split('&&');
  const userEmail =
    document.getElementById('email-field2') && document.getElementById('email-field2').value !== ''
      ? document.getElementById('email-field2').value
      : '';
  axios
    .post(
      process.env.REACT_APP_TOKEN_SERVICE_URL,
      {
        clientId: process.env.REACT_APP_CLIENT_ID,
      },
      {
        headers: {
          Authorization: `Basic ${base64Authorization}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then((response) => {
      axios.post(
        `${process.env.REACT_APP_AGENT_FEEDBACK_CALL_API}`,
        {
          sessionId: sessionID,
          feedbackInput: details[0],
          feedbackRating: details[1],
          source: 'live agent',
          agentName: awsConnectAgentName,
          chatbotktc: lexUserCookie,
          customerDetails,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
    })
    .catch();
  if (
    document.getElementById('email-field2') &&
    document.getElementById('email-field2').value !== '' &&
    document.getElementById('consent2') &&
    userEmail !== '' &&
    document.getElementById('consent2').checked
  ) {
    if (
      /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i.test(
        userEmail
      )
    ) {
      axios
        .post(
          process.env.REACT_APP_TOKEN_SERVICE_URL,
          {
            clientId: process.env.REACT_APP_CLIENT_ID,
          },
          {
            headers: {
              Authorization: `Basic ${base64Authorization}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
        .then((response) => {
          axios.post(
            `${process.env.REACT_APP_EMAIL_API}`,
            {
              userId: lexUserId,
              sessionId: sessionID,
              customerName: userName,
              recipientEmail: userEmail,
              botType: englishBot ? ENG : ESP,
              botConversation: getBotConversation(messages, englishBot),
            },
            {
              headers: {
                Authorization: `Bearer ${response.data.access_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );
        })
        .catch();
    }
  }
};

feedbackProcessApiHandler.propTypes = {
  sessionID: PropTypes.string,
  feedbackDetails: PropTypes.string,
  awsConnectAgentName: PropTypes.string,
  customerDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  userName: PropTypes.string,
  englishBot: PropTypes.bool,
  messages: PropTypes.array,
};
export const languageChangeApiCall = (sessionID, englishBot, triggerType) => {
  axios
    .post(
      process.env.REACT_APP_TOKEN_SERVICE_URL,
      {
        clientId: process.env.REACT_APP_CLIENT_ID,
      },
      {
        headers: {
          Authorization: `Basic ${base64Authorization}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then((response) => {
      axios.post(
        `${process.env.REACT_APP_ANALYTICS_API}`,
        {
          userId: lexUserId,
          sessionId: sessionID,
          type: triggerType,
          eventType: 'LANGUAGE_SWITCH_COUNT',
          locale: englishBot ? ENG : ESP,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
    })
    .catch();
};

languageChangeApiCall.propTypes = {
  sessionID: PropTypes.string,
  englishBot: PropTypes.bool,
  triggerType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export const handleServiceCaseButtons = () => {
  const elems = document.getElementsByClassName('serviceCaseButton');
  if (elems && elems.length > 0) {
    for (const element of elems) {
      element.disabled = true;
      element.style['pointer-events'] = 'none';
    }
  }
};
export const handleGreetButtons = () => {
  const elems = document.getElementsByClassName('greet-button-cls');
  if (elems && elems.length > 0) {
    for (const element of elems) {
      element.disabled = true;
    }
  }
};
export const yearGenerator = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  let startYear = currentYear - 100;
  while (startYear < currentYear) {
    startYear += 1;
    years.push(startYear);
  }
  return years.reverse();
};

export const monthGenerator = () => [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const dateGenerator = () => {
  const date = [];
  for (let index = 1; index <= 31; index++) {
    if (index <= 9) {
      date.push(`0${index}`);
    } else {
      date.push(`${index}`);
    }
  }
  return date;
};
export const isValidDate = (year, month, day) => {
  const result = moment(`${day}/${month}/${year}`, 'DD/MMM/YYYY', true).isValid();
  return !!result;
};

isValidDate.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export const isValidDateWithComparedDate = (year, month, day, comparedDate) => {
  const date = new Date(parseInt(year, 10), parseInt(month - 1, 10), parseInt(day, 10)).setHours(
    0,
    0,
    0,
    0
  );
  const result = moment(`${day}/${month}/${year}`, 'DD/MM/YYYY', true).isValid();
  return !!(result && date >= comparedDate);
};

isValidDateWithComparedDate.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  comparedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export const formatPhoneNumber = (phoneNumberString) => {
  const match = phoneNumberString.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match && phoneNumberString.length === 10) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  if (phoneNumberString.length < 10) {
    return phoneNumberString;
  }
};
formatPhoneNumber.propTypes = {
  phoneNumberString: PropTypes.string,
};
export const endChatApiCall = (englishBot, lexUserID, sessionID, userName, messages) => {
  const userEmail =
    document.getElementById('email-field') && document.getElementById('email-field').value !== ''
      ? document.getElementById('email-field').value
      : '';
  if (
    document.getElementById('email-field') &&
    document.getElementById('email-field').value !== '' &&
    userEmail !== '' &&
    document.getElementById('consent1') &&
    document.getElementById('consent1').checked
  ) {
    if (
      /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i.test(
        userEmail
      )
    ) {
      axios
        .post(
          process.env.REACT_APP_TOKEN_SERVICE_URL,
          {
            clientId: process.env.REACT_APP_CLIENT_ID,
          },
          {
            headers: {
              Authorization: `Basic ${base64Authorization}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
        .then((response) => {
          axios.post(
            `${process.env.REACT_APP_EMAIL_API}`,
            {
              userId: lexUserID,
              sessionId: sessionID,
              customerName: userName,
              recipientEmail: userEmail,
              botType: englishBot ? ENG : ESP,
              botConversation: getBotConversation(messages, englishBot),
            },
            {
              headers: {
                Authorization: `Bearer ${response.data.access_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );
        })
        .catch();
    }
  }
};

endChatApiCall.propTypes = {
  englishBot: PropTypes.bool,
  lexUserID: PropTypes.string,
  sessionID: PropTypes.string,
  userName: PropTypes.string,
  messages: PropTypes.array,
};
