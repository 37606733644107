import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { MessageList, MessageGroup } from '@livechat/ui-kit';
import Modal from './Modal/Modal';
import { ChatWrapperCss } from './utils/WrapperCss';
import {
  focusInChatbot,
  onLoadFunction,
  setMaxLengthForInputBox,
  feedbackProcessApiHandler,
  isValidDateWithComparedDate,
  isLastMessageIncludes,
  endChatApiCall,
} from './utils/CommonFunctions';
import ChatHeader from './containers/ChatHeader';
import ChatLogo from './containers/ChatLogo';
import ChatMessageContainer from './containers/ChatMessageContainer';
import ChatFooter from './containers/ChatFooter';
import ValidationProcess from './containers/ValidationProcess';
import Spinner from './spinner/Spinner';
import StepTwo from './wrapup/StepTwo';
import AgentFeedback from './wrapup/AgentFeedback';
import GenerateLexUserId from '../../connectivity/GenerateLexUserId';
import ServiceToMyAccount from './liveAgent/ServiceToMyAccount';
import GeneralInfo from './liveAgent/GeneralInfo';
import { DIRA_ENG, EIRA_ENG, DIRA_ESP, EIRA_ESP, subDomains } from './utils/CommonData';
import {
  ACCOUNT_NO,
  LOAN_ACCOUNT_NO,
  ACC_NUM,
  ADDITIONAL_DETAILS,
  BACK_TO_CHAT_ENG,
  BACK_TO_CHAT_ESP,
  CANCEL_THIS_PROCESS_ENG,
  CANCEL_THIS_PROCESS_ESP,
  CARD_NO,
  CARD_ACC_NO,
  DB_CARD_ACC_NO,
  CB_LANGUAGE_SWITCH_ENGLISH,
  CB_LANGUAGE_SWITCH_SPANISH,
  CCACCT_NUM,
  CCAMT_TO_PAY,
  CHAT_WITH_BOT_ENDED_ENG,
  CHAT_WITH_BOT_ENDED_ESP,
  CUSTOMER_EMAIL,
  CUSTOMER_MOBILE,
  CUSTOMER_NAME,
  DESTINATION,
  EMAIL,
  END_CHAT_ENG,
  END_CHAT_ESP,
  END_DATE_GREATER_THAN_START_DATE_ENG,
  END_DATE_GREATER_THAN_START_DATE_ESP,
  FULL_NAME,
  INVALID_ACC_NUM,
  INVALID_DATE_FORMAT_ENG,
  INVALID_DATE_FORMAT_ESP,
  INVALID_EMAIL,
  INVALID_FORMAT_ENG,
  INVALID_FORMAT_ESP,
  INVALID_MOBILE_NUM_ENG,
  INVALID_NUMBER,
  INVALID_SSN,
  LASTFOUR_SSN,
  LAST_FOUR_SSN,
  LIVE_AGENT_CHAT_ENDED_ENG,
  LIVE_AGENT_CHAT_ENDED_ESP,
  LNAMT_TO_PAY,
  LOAN_ACCT_NUM,
  MESSAGE_BODY,
  MOB_NUM,
  NEED_ANYTHING_ELSE_ENG,
  NEED_ANYTHING_ELSE_ESP,
  OFCOURSE_WHAT_YOU_NEED_ENG,
  OTHER_AMOUNT,
  PAY_FROM_ACCT_NUM,
  PLEASE_ENTER_CARD_NUMBER_ENG,
  PLEASE_ENTER_CARD_NUMBER_ESP,
  PLEASE_ENTER_NINE_DIGITS_ENG,
  PLEASE_ENTER_NINE_DIGITS_ESP,
  PLEASE_TELL_YOUR_NAME_ENG,
  PLEASE_TELL_YOUR_NAME_ESP,
  PRIMARY_PHONE_NUMBER,
  ROUTING_NUM,
  SHOW_FEEDBACK,
  SOCIAL_SECURITY_NUMBER,
  SSN_SSN,
  TRAVEL_ACC_NUM,
  TRAVEL_DATES,
  WHAT_TO_CHANGE_LANGUAGE_ENG,
  WHAT_TO_CHANGE_LANGUAGE_ESP,
  WHAT_YOU_NEED_ENG,
  WHAT_YOU_NEED_ESP,
  WILL_CONTACT_YOU_SOON_ENG,
  WILL_CONTACT_YOU_SOON_ESP,
  WORKING_HOURS_MON_TO_FRI_ENG,
  WORKING_HOURS_MON_TO_FRI_ESP,
  SELECT_DIRA_MSG_ENG,
  SELECT_DIRA_MSG_ESP,
  SELECT_EIRA_MSG_ENG,
  SELECT_EIRA_MSG_ESP,
  ENG,
  ESP,
} from '../../Constants';
import { getCountriesList } from '../../app/CommonFunction';

const lexUserId = GenerateLexUserId();
const useChatWrapperCss = ChatWrapperCss();
const Maximized = forwardRef(
  (
    {
      i18n,
      minimize,
      messages,
      countriesList,
      handleCountriesList,
      sendToLex,
      handleMessage,
      handleWrapupMessage,
      onCloseClick,
      handleLanguageChange,
      handeLanguageChangeMessage,
      handeButtonLinkMessage,
      handleDateMessage,
      englishBot,
      lexError,
      defaultTextareaContent,
      sessionID,
      pegaIntent,
      slotToElicit,
      handlerPageIntent,
      handlePopupRunningCase,
      btnIntentTrigger,
      handlerIntentTrigger,
      handlerLiveAgentTrigger,
      runningAWSConnect,
      sendDocToConnect,
      waitingForLiveAgent,
      allowUploadDoc,
      awsConnectError,
      agentTypingFlag,
      awsConnectAgentName,
      customerDetails,
      ReconnectToAwsConnect,
      askingForWaitingTime,
      handleButtonClickDuringAgentCallMessage,
      liveAgentJoined,
      sendTypingEventToConnect,
      hideOnCustomerType,
      userEmail,
      setUserEmailFromIntent,
      verfiedCustomer,
      setVerifedCustomer,
      setReconnectionStatus,
      addMessages,
      deleteSessionToLex,
      formInProcessTrigger,
      setCustomerDetails,
      spinnerTrigger,
      spinnerHandler,
      cardValidFlag,
      sendToConnect,
      uploadDocuments,
      attachmentLoader,
      cancelUploadDocuments,
    },
    ref
  ) => {
    const WrapperCss = useChatWrapperCss();
    const [wrapEmail, setWrapEmail] = useState(userEmail);
    const [userName, setUserName] = useState('');
    const [closeTrigger, setCloseTrigger] = useState(false);
    const [agentFeedbackTrigger, setAgentFeedbackTrigger] = useState(false);
    const [showUploadingBar, setShowUploadingBar] = useState(false);
    const [sendBtnActive, setSendBtnActive] = useState(true);
    const [numericKeyboard, setNumericKeyboard] = useState(false);
    const dummyText = localStorage.getItem('textContent')
      ? localStorage.getItem('textContent')
      : defaultTextareaContent;
    const [defaultTextContent, setDefaultTextContent] = useState(dummyText);
    const [maxLength, setMaxLength] = useState(2000);
    const [isValid, setIsValid] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [serviceFormTrigger, setServiceFormTrigger] = useState(false);
    const [generalFormTrigger, setGeneralFormTrigger] = useState(false);
    const [webFormType, setWebFormType] = useState('');
    const [isCancelProcess, setIsCancelProcess] = useState(!!pegaIntent);
    const [isFeedbackProcess, setIsFeedbackProcess] = useState(
      !!(allowUploadDoc && liveAgentJoined)
    );
    const [errorMessage, setErrorMessage] = useState('');
    const [startDateTravel, setStartDateTravel] = useState('');
    const [endDateTravel, setEndDateTravel] = useState('');
    const getLangString = englishBot ? CB_LANGUAGE_SWITCH_ENGLISH : CB_LANGUAGE_SWITCH_SPANISH;
    const [intentLang, setIntentLang] = useState(getLangString);
    const [autoCompleteValue, setAutoCompleteValue] = useState('');
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    const [autoCompleteFlag, setAutoCompleteFlag] = useState(true);
    const [showRegisterCustomerButton, setShowRegisterCustomerButton] = useState(false);
    const [countiresFinal, setCountiresFinal] = useState(false);
    const [dropDownValues, setDropDownValues] = useState([]);
    const [textComposerKey, setTextComposerKey] = useState(2);
    const hiddenFileInput = React.useRef(null);
    useImperativeHandle(ref, () => ({
      closeChatbot() {
        handlerIntentTrigger();
        if (!liveAgentJoined && runningAWSConnect) {
          handlerLiveAgentTrigger();
        }
        setIsCancelProcess(false);
        setIsFeedbackProcess(false);
        onCloseClick();
        minimize();
        localStorage.removeItem('textContent');
        setDefaultTextContent('');
        setTextComposerKey((prev) => prev * 2);
        handlerPageIntent();
        setReconnectionStatus();
        deleteSessionToLex();
      },
    }));
    useEffect(() => {
      focusInChatbot(messages);
      if (messages.length > 0) {
        setMaxLengthForInputBox(
          slotToElicit,
          setMaxLength,
          setNumericKeyboard,
          setAutoCompleteFlag
        );
        if (pegaIntent) {
          setIsCancelProcess(true);
        }
        if (pegaIntent && slotToElicit === DESTINATION && !countiresFinal && autoCompleteFlag) {
          setStartDateTravel('');
          setEndDateTravel('');
          setShowAutoComplete(true);
        }
        if (!cardValidFlag) {
          setShowAutoComplete(false);
        }
        if (pegaIntent && slotToElicit === TRAVEL_DATES) {
          setCountiresFinal(false);
        }
        if (messages[messages.length - 1].message === SHOW_FEEDBACK) {
          setCloseTrigger(true);
        }
        if (
          !pegaIntent ||
          lexError ||
          liveAgentJoined ||
          isLastMessageIncludes(WORKING_HOURS_MON_TO_FRI_ENG, messages) ||
          isLastMessageIncludes(WORKING_HOURS_MON_TO_FRI_ESP, messages)
        ) {
          setIsCancelProcess(false);
        }
        if (
          messages[messages.length - 1].message === BACK_TO_CHAT_ENG ||
          messages[messages.length - 1].message === BACK_TO_CHAT_ESP
        ) {
          const defaultContent = englishBot ? WHAT_YOU_NEED_ENG : WHAT_YOU_NEED_ESP;
          liveAgentJoined
            ? handleButtonClickDuringAgentCallMessage(
                {
                  text: defaultContent,
                  value: defaultContent,
                  showYesNo: false,
                  showWrapupStepTwo: false,
                  messageDate: new Date(),
                },
                false
              )
            : handeButtonLinkMessage(
                {
                  text: defaultContent,
                  value: defaultContent,
                  showYesNo: false,
                  showWrapupStepTwo: false,
                  messageDate: new Date(),
                },
                false
              );
        }
        if (
          messages[messages.length - 1].message !==
          (englishBot ? NEED_ANYTHING_ELSE_ENG : NEED_ANYTHING_ELSE_ESP)
        ) {
          setIsValid(true);
          setSendBtnActive(true);
          setErrorMessage('');
        }
        if (
          isLastMessageIncludes('has entered the chat', messages) ||
          isLastMessageIncludes('se ha unido al chat', messages)
        ) {
          setIsFeedbackProcess(true);
        }
        if (
          messages[messages.length - 1].message ===
          (englishBot ? LIVE_AGENT_CHAT_ENDED_ENG : LIVE_AGENT_CHAT_ENDED_ESP)
        ) {
          setAgentFeedbackTrigger(true);
        }
        if (
          messages[messages.length - 1].message ===
          (englishBot ? CHAT_WITH_BOT_ENDED_ENG : CHAT_WITH_BOT_ENDED_ESP)
        ) {
          setIsCancelProcess(false);
        }
        if (
          messages[messages.length - 1].message.includes(SELECT_DIRA_MSG_ENG) ||
          messages[messages.length - 1].message.includes(SELECT_DIRA_MSG_ESP)
        ) {
          setDropDownValues(englishBot ? DIRA_ENG : DIRA_ESP);
        }
        if (
          messages[messages.length - 1].message.includes(SELECT_EIRA_MSG_ENG) ||
          messages[messages.length - 1].message.includes(SELECT_EIRA_MSG_ESP)
        ) {
          setDropDownValues(englishBot ? EIRA_ENG : EIRA_ESP);
        }
      }
    }, [messages, isCancelProcess, isFeedbackProcess, agentFeedbackTrigger, showModal]);

    useEffect(() => {
      i18n.changeLanguage(englishBot ? ENG : ESP);
      getCountriesList(englishBot, handleCountriesList);
      onLoadFunction(
        englishBot,
        messages.length,
        btnIntentTrigger,
        handlerLiveAgentTrigger,
        handleMessage,
        runningAWSConnect
      );
    }, []);
    useEffect(() => {
      const messageTextarea = document.getElementById('messageText');
      if (messageTextarea) {
        document.getElementById('messageText').focus();
        messageTextarea.selectionStart = messageTextarea.value.length;
        messageTextarea.selectionEnd = messageTextarea.value.length;
      }
    }, [defaultTextContent]);
    useEffect(() => {
      if (slotToElicit === 'knowChatName') {
        const intervalId = window.setInterval(() => {
          if (messages.length > 1) {
            const message = messages[messages.length - 1];
            const diff = moment().diff(moment(message.messageDate), 'seconds');
            if (diff > 9) {
              const defaultContent = englishBot
                ? PLEASE_TELL_YOUR_NAME_ENG
                : PLEASE_TELL_YOUR_NAME_ESP;
              handeButtonLinkMessage(
                { text: defaultContent, value: '', isButtonMessage: false },
                false
              );
            }
          }
        }, 1000);
        return () => {
          clearInterval(intervalId);
        };
      }
    }, [slotToElicit, messages]);
    const fileHandleChange = (event) => {
      setShowUploadingBar(true);
      const fileUploaded = event.target.files[0];
      sendDocToConnect(fileUploaded);
      setTimeout(() => {
        setShowUploadingBar(false);
        handleButtonClickDuringAgentCallMessage(
          {
            text: englishBot
              ? `You sent to ${awsConnectAgentName} <span className={WrapperCss.test_design}>${fileUploaded.name}</span> document.`
              : `Has enviado a ${awsConnectAgentName} el documento <span className={WrapperCss.test_design}>${fileUploaded.name}</span>.`,
            value: '',
            isButtonMessage: false,
          },
          true
        );
      }, 3000);
    };
    const onCancelProcessHandler = () => {
      setIsCancelProcess(false);
      setStartDateTravel('');
      setEndDateTravel('');
      const cancelMsg = englishBot ? CANCEL_THIS_PROCESS_ENG : CANCEL_THIS_PROCESS_ESP;
      const modifiedmessage = '<slot>Cancel chat interaction<slot>';
      if (!liveAgentJoined && runningAWSConnect) {
        handlerLiveAgentTrigger();
        setTimeout(() => {
          handleMessage({ text: cancelMsg, value: modifiedmessage }, true);
        }, 3000);
      } else {
        handleMessage({ text: cancelMsg, value: modifiedmessage }, true);
      }
      handlerPageIntent();
    };
    const onStartDateSelectHandler = (date) => {
      setIsValid(true);
      setErrorMessage('');
      setSendBtnActive(true);
      setStartDateTravel(date);
      localStorage.removeItem('textContent');
      setDefaultTextContent('');
      setTextComposerKey((prev) => prev * 2);
      handleDateMessage(
        { text: date, value: date, showStartDateCalendar: false, showEndDateCalendar: true },
        true
      );
    };
    const onEndDateSelectHandler = (date) => {
      setIsValid(true);
      setErrorMessage('');
      setSendBtnActive(true);
      setEndDateTravel(date);
      localStorage.removeItem('textContent');
      setDefaultTextContent('');
      setTextComposerKey((prev) => prev * 2);
      const modifiedmessage = `${startDateTravel}-${date}`;
      handleDateMessage(
        {
          text: date,
          value: modifiedmessage,
          showStartDateCalendar: false,
          showEndDateCalendar: false,
        },
        true
      );
    };
    const separator = (numb) => {
      if (numb.includes('.')) {
        const str = numb.toString().split('.');
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `$${str.join('.')}`;
      }
      const value = Number(numb)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
      return `$${value}`;
    };
    const handleChange = (e) => {
      if (e) {
        if (liveAgentJoined) {
          sendTypingEventToConnect();
        }
        if (
          e.target.value.match(/<\s?[^>]*\/?\s?>/) ||
          ((slotToElicit === LOAN_ACCT_NUM || slotToElicit === PAY_FROM_ACCT_NUM) &&
            !e.target.value.match(/^[A-Za-z0-9]*$/))
        ) {
          setSendBtnActive(false);
          setIsValid(false);
          const errorMsg = englishBot ? INVALID_FORMAT_ENG : INVALID_FORMAT_ESP;
          setErrorMessage(errorMsg);
        } else if (slotToElicit === DESTINATION && cardValidFlag && autoCompleteFlag) {
          setAutoCompleteValue(e.target.value);
          setShowAutoComplete(true);
          setSendBtnActive(false);
        } else if (
          slotToElicit === MOB_NUM ||
          slotToElicit === CUSTOMER_MOBILE ||
          slotToElicit === PRIMARY_PHONE_NUMBER
        ) {
          setNumericKeyboard(true);
          if (e.target.value.match(/^\d{10}$/)) {
            const mobileNumberInput = e.target.value.match(/^(\d{3})(\d{3})(\d{4})$/);
            const mobileNumber = `(${mobileNumberInput[1]}) ${mobileNumberInput[2]}-${mobileNumberInput[3]}`;
            setDefaultTextContent(mobileNumber);
            setIsValid(true);
            setErrorMessage('');
            setSendBtnActive(true);
            setTextComposerKey((prev) => prev * 2);
          } else if (e.target.value.match(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/)) {
            setIsValid(true);
            setSendBtnActive(true);
            setErrorMessage('');
          } else {
            const str = e.target.value.replace(/[- )(]/g, '');
            setDefaultTextContent(str);
            setSendBtnActive(false);
            if (e.keyCode === 13) {
              e.preventDefault();
            }
            setIsValid(false);
            const errorMsg = englishBot ? INVALID_MOBILE_NUM_ENG : INVALID_FORMAT_ESP;
            setErrorMessage(errorMsg);
            setTextComposerKey((prev) => prev * 2);
          }
          setMaxLength(10);
        } else if (slotToElicit === SOCIAL_SECURITY_NUMBER || slotToElicit === SSN_SSN) {
          setNumericKeyboard(true);
          if (e.target.value.match(/^\d{9}$/)) {
            const SSNInput = e.target.value.match(/^(\d{3})(\d{2})(\d{4})$/);
            const SSN = `${SSNInput[1]}-${SSNInput[2]}-${SSNInput[3]}`;
            setDefaultTextContent(SSN);
            setIsValid(true);
            setErrorMessage('');
            setSendBtnActive(true);
            setTextComposerKey((prev) => prev * 2);
          } else if (e.target.value.match(/^(\()?\d{3}(\))?(-|\s)?\d{2}(-|\s)\d{4}$/)) {
            setIsValid(true);
            setSendBtnActive(true);
            setErrorMessage('');
          } else {
            const str = e.target.value.replace(/[- )(]/g, '');
            setDefaultTextContent(str);
            setSendBtnActive(false);
            if (e.keyCode === 13) {
              e.preventDefault();
            }
            setIsValid(false);
            const errorMsg = englishBot ? INVALID_SSN : INVALID_FORMAT_ESP;
            setErrorMessage(errorMsg);
            setTextComposerKey((prev) => prev * 2);
          }
          setMaxLength(9);
        } else if (slotToElicit === ACC_NUM) {
          setNumericKeyboard(true);
          if (e.target.value.match(/^\d{10}$/)) {
            setDefaultTextContent(e.target.value);
            setIsValid(true);
            setErrorMessage('');
            setSendBtnActive(true);
            setTextComposerKey((prev) => prev * 2);
          } else {
            setDefaultTextContent(e.target.value);
            setSendBtnActive(false);
            if (e.keyCode === 13) {
              e.preventDefault();
            }
            setIsValid(false);
            const errorMsg = englishBot ? INVALID_ACC_NUM : INVALID_FORMAT_ESP;
            setErrorMessage(errorMsg);
            setTextComposerKey((prev) => prev * 2);
          }
          setMaxLength(10);
        } else if (slotToElicit === LAST_FOUR_SSN || slotToElicit === LASTFOUR_SSN) {
          setNumericKeyboard(true);
          if (e.target.value.match(/^\d{4}$/)) {
            setDefaultTextContent(e.target.value);
            setIsValid(true);
            setErrorMessage('');
            setSendBtnActive(true);
            setTextComposerKey((prev) => prev * 2);
          } else {
            setDefaultTextContent(e.target.value);
            setSendBtnActive(false);
            if (e.keyCode === 13) {
              e.preventDefault();
            }
            setIsValid(false);
            const errorMsg = englishBot ? INVALID_SSN : INVALID_FORMAT_ESP;
            setErrorMessage(errorMsg);
            setTextComposerKey((prev) => prev * 2);
          }
          setMaxLength(4);
        } else if (slotToElicit === ROUTING_NUM) {
          setNumericKeyboard(true);
          if (e.target.value.match(/^\d{9}$/)) {
            setDefaultTextContent(e.target.value);
            setIsValid(true);
            setErrorMessage('');
            setSendBtnActive(true);
            setTextComposerKey((prev) => prev * 2);
          } else {
            setDefaultTextContent(e.target.value);
            setSendBtnActive(false);
            if (e.keyCode === 13) {
              e.preventDefault();
            }
            setIsValid(false);
            const errorMsg = englishBot
              ? PLEASE_ENTER_NINE_DIGITS_ENG
              : PLEASE_ENTER_NINE_DIGITS_ESP;
            setErrorMessage(errorMsg);
            setTextComposerKey((prev) => prev * 2);
          }
          setMaxLength(9);
        } else if (slotToElicit === LNAMT_TO_PAY || slotToElicit === CCAMT_TO_PAY) {
          setNumericKeyboard(true);
          if (e.target.value.match(/^\d*$/)) {
            setDefaultTextContent(e.target.value);
            setIsValid(true);
            setErrorMessage('');
            setSendBtnActive(true);
            setTextComposerKey((prev) => prev * 2);
          } else {
            setDefaultTextContent(e.target.value);
            setSendBtnActive(false);
            if (e.keyCode === 13) {
              e.preventDefault();
            }
            setIsValid(false);
            const errorMsg = englishBot ? INVALID_NUMBER : INVALID_FORMAT_ESP;
            setErrorMessage(errorMsg);
            setTextComposerKey((prev) => prev * 2);
          }
        } else if (slotToElicit === OTHER_AMOUNT) {
          const rgx = /^\d*\.?\d*$/;
          setNumericKeyboard(true);
          if (e.target.value.match(rgx)) {
            setDefaultTextContent(e.target.value);
            setIsValid(true);
            setErrorMessage('');
            setSendBtnActive(true);
            setTextComposerKey((prev) => prev * 2);
          } else {
            setDefaultTextContent(e.target.value);
            setSendBtnActive(false);
            if (e.keyCode === 13) {
              e.preventDefault();
            }
            setIsValid(false);
            const errorMsg = englishBot ? INVALID_NUMBER : INVALID_FORMAT_ESP;
            setErrorMessage(errorMsg);
            setTextComposerKey((prev) => prev * 2);
          }
        } else if (slotToElicit === TRAVEL_ACC_NUM || slotToElicit === CCACCT_NUM) {
          setNumericKeyboard(true);
          if (e.target.value.match(/^\d{16}$/)) {
            setDefaultTextContent(e.target.value);
            setIsValid(true);
            setErrorMessage('');
            setSendBtnActive(true);
            setTextComposerKey((prev) => prev * 2);
          } else {
            setDefaultTextContent(e.target.value);
            setSendBtnActive(false);
            if (e.keyCode === 13) {
              e.preventDefault();
            }
            setIsValid(false);
            const errorMsg = englishBot
              ? PLEASE_ENTER_CARD_NUMBER_ENG
              : PLEASE_ENTER_CARD_NUMBER_ESP;
            setErrorMessage(errorMsg);
            setTextComposerKey((prev) => prev * 2);
          }
          setMaxLength(16);
        } else if (slotToElicit === CUSTOMER_EMAIL || slotToElicit === EMAIL) {
          setMaxLength(250);
          if (
            /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i.test(
              e.target.value
            )
          ) {
            setIsValid(true);
            setErrorMessage('');
            setSendBtnActive(true);
          } else {
            setSendBtnActive(false);
            setIsValid(false);
            const errorMsg = englishBot ? INVALID_EMAIL : INVALID_FORMAT_ESP;
            setErrorMessage(errorMsg);
          }
        } else if (slotToElicit === TRAVEL_DATES && startDateTravel === '') {
          setMaxLength(250);
          if (e.target.value.match(/^([0-2]\d|(3)[0-1])(\/)(((0)\d)|((1)[0-2]))(\/)\d{4}$/)) {
            const formattedDate = e.target.value;
            const dateArray = formattedDate.split('/');
            const today = new Date().setHours(0, 0, 0, 0);
            if (isValidDateWithComparedDate(dateArray[2], dateArray[1], dateArray[0], today)) {
              setIsValid(true);
              setErrorMessage('');
              setSendBtnActive(true);
            } else {
              setSendBtnActive(false);
              setIsValid(false);
              const errorMsg = englishBot ? INVALID_DATE_FORMAT_ENG : INVALID_DATE_FORMAT_ESP;
              setErrorMessage(errorMsg);
            }
          } else {
            setSendBtnActive(false);
            setIsValid(false);
            const errorMsg = englishBot ? INVALID_DATE_FORMAT_ENG : INVALID_DATE_FORMAT_ESP;
            setErrorMessage(errorMsg);
          }
        } else if (messages[messages.length - 1].showEndDateCalendar && startDateTravel !== '') {
          if (e.target.value.match(/^([0-2]\d|(3)[0-1])(\/)(((0)\d)|((1)[0-2]))(\/)\d{4}$/)) {
            const formattedDate = e.target.value;
            const dateArray = formattedDate.split('/');
            const startDate = startDateTravel.split('/');
            const startDateSelected = new Date(
              parseInt(startDate[2], 10),
              parseInt(startDate[1] - 1, 10),
              parseInt(startDate[0], 10)
            ).setHours(0, 0, 0, 0);
            if (
              isValidDateWithComparedDate(
                dateArray[2],
                dateArray[1],
                dateArray[0],
                startDateSelected
              )
            ) {
              setIsValid(true);
              setErrorMessage('');
              setSendBtnActive(true);
            } else {
              setSendBtnActive(false);
              setIsValid(false);
              const date = new Date(
                parseInt(dateArray[2], 10),
                parseInt(dateArray[1] - 1, 10),
                parseInt(dateArray[0], 10)
              ).setHours(0, 0, 0, 0);
              let errorMsg = '';
              if (date < startDateSelected) {
                errorMsg = englishBot
                  ? END_DATE_GREATER_THAN_START_DATE_ENG
                  : END_DATE_GREATER_THAN_START_DATE_ESP;
              } else {
                errorMsg = englishBot ? INVALID_DATE_FORMAT_ENG : INVALID_DATE_FORMAT_ESP;
              }
              setErrorMessage(errorMsg);
            }
          } else {
            setSendBtnActive(false);
            setIsValid(false);
            const errorMsg = englishBot ? INVALID_DATE_FORMAT_ENG : INVALID_DATE_FORMAT_ESP;
            setErrorMessage(errorMsg);
          }
        } else {
          setMaxLengthForInputBox(
            slotToElicit,
            setMaxLength,
            setNumericKeyboard,
            setAutoCompleteFlag
          );
          setIsValid(true);
          setErrorMessage('');
          setSendBtnActive(true);
        }
        localStorage.setItem('textContent', e.target.value);
      }
    };

    const closeEvent = () => {
      if (agentFeedbackTrigger) {
        endChat();
      } else if (liveAgentJoined) {
        setAgentFeedbackTrigger(true);
      } else if (
        !closeTrigger &&
        !lexError &&
        messages.length > 0 &&
        messages[messages.length - 1].message !== SHOW_FEEDBACK
      ) {
        setServiceFormTrigger(false);
        setGeneralFormTrigger(false);
        formInProcessTrigger(false);
        setCloseTrigger(true);
      } else {
        endChat();
      }
      localStorage.removeItem('textContent');
    };
    const endChat = () => {
      setReconnectionStatus();
      messages.push({
        message: englishBot ? END_CHAT_ENG : END_CHAT_ESP,
        messageDate: new Date(),
        isOwn: true,
      });
      endChatApiCall(englishBot, lexUserId, sessionID, userName, messages);
      deleteSessionToLex();
      setIsCancelProcess(false);
      setIsFeedbackProcess(false);
      if (!liveAgentJoined && runningAWSConnect) {
        handlerLiveAgentTrigger();
      }
      handlerPageIntent();
      localStorage.removeItem('textContent');
      onCloseClick();
      if ('parentIFrame' in window) {
        window.parentIFrame.getPageInfo((obj) => {
          window.parentIFrame.size(100, 140);
        });
        for (const target of subDomains) {
          window.parent.postMessage('chatbot closed', target);
        }
      }
      minimize();
      setCloseTrigger(false);
      setDefaultTextContent('');
    };
    const endChatFromAgentFeedback = (feedbackInput, feedbackRating) => {
      messages.push({
        message: englishBot ? END_CHAT_ENG : END_CHAT_ESP,
        messageDate: new Date(),
        isOwn: true,
      });
      const feedbackDetails = `${feedbackInput}&&${feedbackRating}`;
      handlerLiveAgentTrigger();
      feedbackProcessApiHandler(
        sessionID,
        feedbackDetails,
        awsConnectAgentName,
        customerDetails,
        userName,
        englishBot,
        messages
      );
      deleteSessionToLex();
      setIsCancelProcess(false);
      setIsFeedbackProcess(false);
      setAgentFeedbackTrigger(false);
      handlerPageIntent();
      localStorage.removeItem('textContent');
      onCloseClick();
      if ('parentIFrame' in window) {
        window.parentIFrame.getPageInfo((obj) => {
          window.parentIFrame.size(100, 140);
        });
      }
      minimize();
      setCloseTrigger(false);
      setDefaultTextContent('');
    };
    window.onbeforeunload = (e) => {
      localStorage.removeItem('textContent');
    };
    const handleWrapUpStepOneYes = () => {
      const yesButton = englishBot ? 'Yes' : 'Sí';
      messages.push({ message: yesButton, isButtonMessage: true, isOwn: true });
      let defaultContent = '';
      if (
        isLastMessageIncludes(WILL_CONTACT_YOU_SOON_ENG, messages) ||
        isLastMessageIncludes(WILL_CONTACT_YOU_SOON_ESP, messages)
      ) {
        defaultContent = englishBot ? OFCOURSE_WHAT_YOU_NEED_ENG : WHAT_YOU_NEED_ESP;
      } else {
        defaultContent = englishBot ? WHAT_YOU_NEED_ENG : WHAT_YOU_NEED_ESP;
      }
      handleWrapupMessage(
        { text: defaultContent, value: defaultContent, showYesNo: false, messageDate: new Date() },
        false
      );
    };
    const handleWrapUpStepOneNo = () => {
      handeButtonLinkMessage({ text: 'No', value: '', isButtonMessage: true }, true);
      setCloseTrigger(true);
    };
    const continueChat = () => {
      setCloseTrigger(false);
      setAgentFeedbackTrigger(false);
      spinnerHandler(false);
      const defaultContinueChat = englishBot ? BACK_TO_CHAT_ENG : BACK_TO_CHAT_ESP;
      handeButtonLinkMessage({ text: defaultContinueChat, value: '', isButtonMessage: true }, true);
    };
    const continueChatWithAgent = () => {
      setAgentFeedbackTrigger(false);
      const defaultContinueChat = englishBot ? BACK_TO_CHAT_ENG : BACK_TO_CHAT_ESP;
      handeButtonLinkMessage({ text: defaultContinueChat, value: '', isButtonMessage: true }, true);
    };
    const changeLanguage = () => {
      const defaultContent = englishBot ? WHAT_TO_CHANGE_LANGUAGE_ESP : WHAT_TO_CHANGE_LANGUAGE_ENG;
      handeLanguageChangeMessage(
        { text: defaultContent, value: '', languageChangeYesNo: true },
        false
      );
      setCloseTrigger(false);
    };
    const modalHandler = () => {
      handeButtonLinkMessage({ text: 'Modal Close', value: '', isButtonMessage: true }, true);
      setShowModal(false);
      handlePopupRunningCase(false);
    };
    const setFormType = () => {
      setWebFormType('');
      handlePopupRunningCase(false);
    };
    const handleSendButtonClick = (msg) => {
      localStorage.removeItem('textContent');
      setShowAutoComplete(false);
      setDefaultTextContent('');
      setTextComposerKey((prev) => prev * 2);
      setCloseTrigger(false);
      if (msg !== '') {
        if (slotToElicit === MESSAGE_BODY) {
          setIsCancelProcess(false);
          handleMessage({ text: msg, value: msg }, true);
        } else if (
          slotToElicit === ACCOUNT_NO ||
          slotToElicit === CARD_NO ||
          slotToElicit === TRAVEL_ACC_NUM ||
          slotToElicit === ACC_NUM ||
          slotToElicit === LOAN_ACCOUNT_NO ||
          slotToElicit === CARD_ACC_NO ||
          slotToElicit === DB_CARD_ACC_NO
        ) {
          let textMessage = msg;
          textMessage = textMessage?.replace(/.(?=.{4})/g, 'X');
          handleMessage({ text: textMessage, value: msg }, true);
        } else if (slotToElicit === OTHER_AMOUNT) {
          const textMessage = separator(msg);
          handleMessage({ text: textMessage, value: msg }, true);
        } else if (slotToElicit === SOCIAL_SECURITY_NUMBER || slotToElicit === SSN_SSN) {
          let textMessage = msg;
          textMessage = textMessage?.replace(/([^- )(])(?=.{4})/g, 'X');
          if (slotToElicit === SSN_SSN) {
            handleMessage({ text: textMessage, value: msg }, true);
          } else {
            handleMessage({ text: textMessage, value: msg, type: 'serviceCase' }, true);
          }
        } else if (
          slotToElicit === PRIMARY_PHONE_NUMBER ||
          slotToElicit === MOB_NUM ||
          slotToElicit === CUSTOMER_MOBILE
        ) {
          setNumericKeyboard(false);
          setIsValid(true);
          setErrorMessage('');
          handleMessage({ text: msg, value: msg }, true);
        } else if (slotToElicit === CUSTOMER_EMAIL || slotToElicit === EMAIL) {
          setWrapEmail(msg);
          setUserEmailFromIntent(msg);
          handleMessage({ text: msg, value: msg }, true);
        } else if (slotToElicit === FULL_NAME || slotToElicit === CUSTOMER_NAME) {
          setUserName(msg);
          handleMessage({ text: msg, value: msg }, true);
        } else if (slotToElicit === TRAVEL_DATES && startDateTravel === '') {
          setErrorMessage('');
          setEndDateTravel('');
          setStartDateTravel(msg);
          handleDateMessage(
            {
              text: msg,
              value: msg,
              showStartDateCalendar: false,
              showEndDateCalendar: true,
            },
            true
          );
        } else if (slotToElicit === DESTINATION) {
          setAutoCompleteValue('');
          handleMessage({ text: msg, value: msg }, true);
        } else if (messages[messages.length - 1].showEndDateCalendar && startDateTravel !== '') {
          setErrorMessage('');
          setEndDateTravel(msg);
          setStartDateTravel('');
          const modifiedmessage = `${startDateTravel}-${msg}`;
          handleDateMessage(
            {
              text: msg,
              value: modifiedmessage,
              showStartDateCalendar: false,
              showEndDateCalendar: false,
            },
            true
          );
        } else if (slotToElicit === ADDITIONAL_DETAILS) {
          setIsCancelProcess(false);
          handlerPageIntent();
          handleMessage({ text: msg, value: msg }, true);
        } else if (msg === 'concierge') {
          setServiceFormTrigger(true);
          formInProcessTrigger(true);
          spinnerHandler(true);
          setShowRegisterCustomerButton(false);
          handeButtonLinkMessage({ text: msg, value: '', isButtonMessage: false }, true);
        } else {
          handleMessage({ text: msg, value: msg }, true);
        }
      }
    };
    return (
      <>
        {showModal && (
          <Modal
            englishBot={!!englishBot}
            sessionID={sessionID}
            FormType={webFormType}
            modalHandler={modalHandler}
            setFormType={setFormType}
          />
        )}
        <div className="main-container">
          <ChatHeader
            englishBot={!!englishBot}
            changeLanguage={changeLanguage}
            minimize={minimize}
            closeEvent={closeEvent}
          />
          <ChatLogo />
          <div id="messageList" className={WrapperCss.message_list_container}>
            <MessageList
              active
              containScrollInSubtree
              id="messageContainer"
              className={
                closeTrigger || agentFeedbackTrigger ? 'messageContainerWrap' : 'messageContainer'
              }
            >
              {!closeTrigger &&
              !agentFeedbackTrigger &&
              !serviceFormTrigger &&
              !generalFormTrigger ? (
                <div>
                  {messages.length > 0 && (
                    <MessageGroup id="messageGroup" onlyFirstWithMeta>
                      <ChatMessageContainer
                        messages={messages}
                        countriesList={countriesList}
                        lexError={lexError}
                        englishBot={englishBot}
                        startDateTravel={startDateTravel}
                        endDateTravel={endDateTravel}
                        agentTypingFlag={agentTypingFlag}
                        intentLang={intentLang}
                        slotToElicit={slotToElicit}
                        runningAWSConnect={runningAWSConnect}
                        sessionID={sessionID}
                        sendToLex={sendToLex}
                        setMaxLength={setMaxLength}
                        handleMessage={handleMessage}
                        onEndDateSelectHandler={onEndDateSelectHandler}
                        handeLanguageChangeMessage={handeLanguageChangeMessage}
                        handlerLiveAgentTrigger={handlerLiveAgentTrigger}
                        ReconnectToAwsConnect={ReconnectToAwsConnect}
                        handleWrapUpStepOneYes={handleWrapUpStepOneYes}
                        setDefaultTextContent={setDefaultTextContent}
                        handleWrapUpStepOneNo={handleWrapUpStepOneNo}
                        onStartDateSelectHandler={onStartDateSelectHandler}
                        handeButtonLinkMessage={handeButtonLinkMessage}
                        handleLanguageChange={handleLanguageChange}
                        setIntentLang={setIntentLang}
                        setWebFormType={setWebFormType}
                        handlePopupRunningCase={handlePopupRunningCase}
                        setShowModal={setShowModal}
                        closeEvent={closeEvent}
                        setIsCancelProcess={setIsCancelProcess}
                        handlerPageIntent={handlerPageIntent}
                        askingForWaitingTime={askingForWaitingTime}
                        setServiceFormTrigger={setServiceFormTrigger}
                        setGeneralFormTrigger={setGeneralFormTrigger}
                        formInProcessTrigger={formInProcessTrigger}
                        spinnerHandler={spinnerHandler}
                        setCountiresFinal={setCountiresFinal}
                        setAutoCompleteFlag={setAutoCompleteFlag}
                        setShowAutoComplete={setShowAutoComplete}
                        setShowRegisterCustomerButton={setShowRegisterCustomerButton}
                        dropDownValues={dropDownValues}
                        handleWrapupMessage={handleWrapupMessage}
                        uploadDocuments={uploadDocuments}
                        attachmentLoader={attachmentLoader}
                        cancelUploadDocuments={cancelUploadDocuments}
                      />
                      {(waitingForLiveAgent || agentTypingFlag || spinnerTrigger) &&
                        !hideOnCustomerType &&
                        !awsConnectError && (
                          <Spinner agentTypingFlag={agentTypingFlag} englishBot={englishBot} />
                        )}
                    </MessageGroup>
                  )}{' '}
                </div>
              ) : (
                !lexError &&
                !agentFeedbackTrigger &&
                !serviceFormTrigger &&
                !generalFormTrigger &&
                !liveAgentJoined && (
                  <StepTwo
                    continueChat={continueChat}
                    wrapEmail={wrapEmail}
                    userName={userName}
                    sessionID={sessionID}
                    englishBot={englishBot}
                    endChat={endChat}
                    sendToLex={sendToLex}
                  />
                )
              )}
              {agentFeedbackTrigger && (
                <AgentFeedback
                  continueChatWithAgent={continueChatWithAgent}
                  continueChat={continueChat}
                  liveAgentAvailable={!!liveAgentJoined}
                  wrapEmail={wrapEmail}
                  userName={userName}
                  awsConnectAgentName={awsConnectAgentName}
                  sessionID={sessionID}
                  englishBot={englishBot}
                  endChatFromAgentFeedback={endChatFromAgentFeedback}
                />
              )}
              {serviceFormTrigger && !agentFeedbackTrigger && (
                <ServiceToMyAccount
                  englishBot={!!englishBot}
                  sessionID={sessionID}
                  setServiceFormTrigger={setServiceFormTrigger}
                  setGeneralFormTrigger={setGeneralFormTrigger}
                  formInProcessTrigger={formInProcessTrigger}
                  handeButtonLinkMessage={handeButtonLinkMessage}
                  setCustomerDetails={setCustomerDetails}
                  spinnerHandler={spinnerHandler}
                  setShowRegisterCustomerButton={setShowRegisterCustomerButton}
                  liveAgentJoined={liveAgentJoined}
                  sendToConnect={sendToConnect}
                />
              )}
              {generalFormTrigger && !agentFeedbackTrigger && (
                <GeneralInfo
                  englishBot={!!englishBot}
                  sessionID={sessionID}
                  setGeneralFormTrigger={setGeneralFormTrigger}
                  setServiceFormTrigger={setServiceFormTrigger}
                  formInProcessTrigger={formInProcessTrigger}
                  handeButtonLinkMessage={handeButtonLinkMessage}
                  setCustomerDetails={setCustomerDetails}
                  spinnerHandler={spinnerHandler}
                  setShowRegisterCustomerButton={setShowRegisterCustomerButton}
                />
              )}
            </MessageList>
          </div>
          {!closeTrigger && !agentFeedbackTrigger && !serviceFormTrigger && !generalFormTrigger && (
            <ValidationProcess
              englishBot={!!englishBot}
              runningAWSConnect={runningAWSConnect}
              showUploadingBar={showUploadingBar}
              liveAgentJoined={liveAgentJoined}
              isFeedbackProcess={isFeedbackProcess}
              setAgentFeedbackTrigger={setAgentFeedbackTrigger}
              onCancelProcessHandler={onCancelProcessHandler}
              isValid={isValid}
              isCancelProcess={isCancelProcess}
              errorMessage={errorMessage}
              waitingForLiveAgent={waitingForLiveAgent}
              setServiceFormTrigger={setServiceFormTrigger}
              formInProcessTrigger={formInProcessTrigger}
              showRegisterCustomerButton={showRegisterCustomerButton}
            />
          )}
          {messages.length > 0 &&
            messages[0].message !== '' &&
            !closeTrigger &&
            !agentFeedbackTrigger &&
            !serviceFormTrigger &&
            !generalFormTrigger && (
              <ChatFooter
                englishBot={!!englishBot}
                handleChange={handleChange}
                addMessages={addMessages}
                textComposerKey={textComposerKey}
                setTextComposerKey={setTextComposerKey}
                waitingForLiveAgent={waitingForLiveAgent}
                isFeedbackProcess={isFeedbackProcess}
                runningAWSConnect={runningAWSConnect}
                liveAgentJoined={liveAgentJoined}
                sendBtnActive={sendBtnActive}
                handleSendButtonClick={handleSendButtonClick}
                defaultTextContent={defaultTextContent}
                fileHandleChange={fileHandleChange}
                hiddenFileInput={hiddenFileInput}
                maxLength={maxLength}
                numericKeyboard={numericKeyboard}
                slotToElicit={slotToElicit}
                setSendBtnActive={setSendBtnActive}
                setDefaultTextContent={setDefaultTextContent}
                autoCompleteValue={autoCompleteValue}
                setAutoCompleteValue={setAutoCompleteValue}
                showAutoComplete={showAutoComplete}
                setShowAutoComplete={setShowAutoComplete}
              />
            )}
        </div>
      </>
    );
  }
);
export default Maximized;

Maximized.propTypes = {
  i18n: PropTypes.object,
  minimize: PropTypes.func,
  messages: PropTypes.array,
  countriesList: PropTypes.array,
  sendToLex: PropTypes.func,
  handleMessage: PropTypes.func,
  handleWrapupMessage: PropTypes.func,
  handleCountriesList: PropTypes.func,
  onCloseClick: PropTypes.func,
  handleLanguageChange: PropTypes.func,
  handeLanguageChangeMessage: PropTypes.func,
  handeButtonLinkMessage: PropTypes.func,
  handleDateMessage: PropTypes.func,
  englishBot: PropTypes.bool,
  lexError: PropTypes.bool,
  defaultTextareaContent: PropTypes.string,
  sessionID: PropTypes.string,
  pegaIntent: PropTypes.bool,
  slotToElicit: PropTypes.string,
  handlerPageIntent: PropTypes.func,
  handlePopupRunningCase: PropTypes.func,
  btnIntentTrigger: PropTypes.string,
  handlerIntentTrigger: PropTypes.func,
  handlerLiveAgentTrigger: PropTypes.func,
  runningAWSConnect: PropTypes.bool,
  sendDocToConnect: PropTypes.func,
  waitingForLiveAgent: PropTypes.bool,
  allowUploadDoc: PropTypes.bool,
  awsConnectError: PropTypes.bool,
  agentTypingFlag: PropTypes.bool,
  awsConnectAgentName: PropTypes.string,
  customerDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ReconnectToAwsConnect: PropTypes.func,
  askingForWaitingTime: PropTypes.func,
  handleButtonClickDuringAgentCallMessage: PropTypes.func,
  liveAgentJoined: PropTypes.bool,
  sendTypingEventToConnect: PropTypes.func,
  hideOnCustomerType: PropTypes.bool,
  userEmail: PropTypes.string,
  setUserEmailFromIntent: PropTypes.func,
  verfiedCustomer: PropTypes.bool,
  setVerifedCustomer: PropTypes.func,
  setReconnectionStatus: PropTypes.func,
  addMessages: PropTypes.func,
  deleteSessionToLex: PropTypes.func,
  formInProcessTrigger: PropTypes.func,
  setCustomerDetails: PropTypes.func,
  spinnerTrigger: PropTypes.bool,
  spinnerHandler: PropTypes.func,
  cardValidFlag: PropTypes.bool,
  sendToConnect: PropTypes.func,
  uploadDocuments: PropTypes.func,
  attachmentLoader: PropTypes.bool,
  cancelUploadDocuments: PropTypes.func,
};
