import * as React from 'react';

// import entire SDK
// import AWS from 'aws-sdk';

import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { isMobile, isAndroid, isIOS, isWindows } from 'react-device-detect';
import { ThemeProvider, FixedWrapper } from '@livechat/ui-kit';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import Maximized from '../components/chat/Maximized';
import Minimized from '../components/chat/Minimized';
import {
  defaultCustomerData,
  themeCSS,
  intentArrWithPegaTrue,
  intentArrWithPegaFalse,
} from './AppData';
import {
  base64Authorization,
  getParameters,
  downloadAttachmentFile,
  timeConvert,
  disableAllChatButtons,
  toggleChatbotContainerSize,
  deleteSessioApiCall,
  focusInChatbot,
  postAttachments,
  generateuuid,
  postFileUpload,
  generateFileData,
  getCountriesList,
} from './CommonFunction';
import {
  ALL_AGENTS_BUSY_EST_TIME_ENG,
  ALL_AGENTS_BUSY_EST_TIME_ESP,
  CHAT_WITH_BOT_ENDED_ENG,
  CHAT_WITH_BOT_ENDED_ESP,
  CONNECTING_TO_LIVR_AGENT_ENG,
  CONNECTING_TO_LIVR_AGENT_ESP,
  CONTACT_US_ENG,
  CONTACT_US_ESP,
  CONTACT_YOU_SOON_ENG,
  CONTACT_YOU_SOON_ESP,
  ENG,
  ESP,
  ESTIMATED_WAIT_TIME_IS,
  ESTIMATED_WAIT_TIME_IS_ESP,
  LIVE_AGENT_CHAT_ENDED_ENG,
  LIVE_AGENT_CHAT_ENDED_ESP,
  MORE_THAN_FIVE_ENG,
  MORE_THAN_FIVE_ESP,
  NEED_ANYTHING_ELSE_ENG,
  NEED_ANYTHING_ELSE_ESP,
  SHOW_FEEDBACK,
  SOMETHING_WENT_WRONG_ENG,
  SOMETHING_WENT_WRONG_ESP,
  SORRY_AGENTS_ARE_BUSY_ENG,
  SORRY_AGENTS_BUSY_ESP,
  TRY_AGAIN_LATER_ENG,
  TRY_AGAIN_LATER_ESP,
  WAIT_FOR_LIVE_AGENT_ENG,
  WAIT_FOR_LIVE_AGENT_ESP,
} from '../Constants';

const { connect } = window;
const urlParams = new URLSearchParams(window.location.search);
const browserLanguage = urlParams.get('dc_language');
const SspUserLogin = urlParams.get('userLogin');
const chatIntent = urlParams.get('chat_intent');
const SspSiteFlagValue = urlParams.get('showIntentAsString');
const deviceType = isMobile ? 'Mobile' : 'Desktop';
let deviceOS = '';
if (isMobile && isAndroid) {
  deviceOS = 'Android';
} else if (isMobile && isIOS) {
  deviceOS = 'IOS';
} else {
  deviceOS = '';
}
let customerChatSession = {};
let cusData = defaultCustomerData;
let intialWaitingTime = 0;
class App extends React.Component {
  theme = themeCSS;

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      messages: [],
      countriesList: [],
      englishBot: browserLanguage !== 'ES',
      btnIntentTrigger: chatIntent || '',
      originalBotLanguage: browserLanguage !== 'ES',
      lexError: false,
      sessionID: '',
      userEmail: '',
      pegaIntent: false,
      defaultTextContent: '',
      chatDeviceType: deviceType !== '' ? deviceType : '',
      chatDeviceOS: isWindows ? 'Windows' : deviceOS,
      popupRunning: false,
      slotToElicit: '',
      liveAgent: false,
      liveAgentJoined: false,
      liveAgentDisconnected: false,
      waitingForLiveAgent: false,
      allowUploadDoc: false,
      agentTypingFlag: false,
      awsConnectAgentName: '',
      customerDetails: {},
      askForTimeAgain: false,
      hideOnCustomerType: false,
      verfiedCustomer: false,
      connectionSucessful: false,
      gotGreeting: false,
      reConnect: false,
      formInProcess: false,
      spinnerTrigger: false,
      cardValidFlag: false,
      sspSiteFlag: SspSiteFlagValue,
      attachmentLoader: false,
      postLexToken: '',
    };
    const { i18n } = this.props;
    i18n.changeLanguage(browserLanguage === 'ES' ? ESP : ENG);
  }

  componentDidMount() {
    window.addEventListener('message', (event) => {
      // if (!event.origin.includes('.orientalbank.com')) return;
      if (typeof event.data === 'object' && 'chat_intent' in event.data) {
        this.setState({
          btnIntentTrigger: event.data.chat_intent,
          sspSiteFlag: event.data.showIntentAsString,
        });
      }
      if (
        event?.data !== 'undefined' &&
        !event?.data?.includes('[iFrameSizer]') &&
        'chat_intent' in event.data === false
      ) {
        this.setState({ btnIntentTrigger: event.data });
      }
    });
    this.intervalId = window.setInterval(() => {
      let message = {};
      const { messages, customerDetails, liveAgent, askForTimeAgain, connectionSucessful } =
        this.state;
      if (messages.length > 0) {
        message = messages[messages.length - 1];
        const diff = moment().diff(moment(message.messageDate), 'seconds');
        this.wrapUpcallFunction(diff, message);
        this.waitTimeLimitOverForLiveAgent(message, diff);
        if (
          (message.message.includes(CONNECTING_TO_LIVR_AGENT_ENG) ||
            message.message.includes(CONNECTING_TO_LIVR_AGENT_ESP)) &&
          !liveAgent &&
          !connectionSucessful
        ) {
          this.setState({
            waitingForLiveAgent: true,
            connectionSucessful: true,
            spinnerTrigger: false,
          });
          this.AWSConnectInit(customerDetails);
        }

        if (askForTimeAgain) {
          this.sendAgentWaitingTimeMessageTrigger(intialWaitingTime, diff);
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  handleCountriesList = (dataList) => {
    const { countriesList } = this.state;
    this.setState({
      countriesList: dataList,
    });
  };

  wrapUpcallFunction = (diff, message) => {
    const { englishBot, lexError, popupRunning, liveAgentJoined, liveAgent, formInProcess } =
      this.state;
    if (
      diff > 149 &&
      !popupRunning &&
      !liveAgentJoined &&
      !liveAgent &&
      !formInProcess &&
      !lexError
    ) {
      if (
        message.message !== (englishBot ? NEED_ANYTHING_ELSE_ENG : NEED_ANYTHING_ELSE_ESP) &&
        message.message !== SHOW_FEEDBACK &&
        message.message.includes(CONTACT_YOU_SOON_ENG) === false &&
        message.message.includes(CONTACT_YOU_SOON_ESP) === false &&
        (message.showWrapupStepTwo === false || undefined === message.showWrapupStepTwo)
      ) {
        this.handleWrapupMessage(
          {
            text: englishBot ? NEED_ANYTHING_ELSE_ENG : NEED_ANYTHING_ELSE_ESP,
            value: '',
            showYesNo: true,
            msgFromLex: true,
            messageDate: new Date(),
          },
          false
        );
      }
    }
    if (
      diff > 29 &&
      !popupRunning &&
      !formInProcess &&
      !liveAgentJoined &&
      message.message === (englishBot ? NEED_ANYTHING_ELSE_ENG : NEED_ANYTHING_ELSE_ESP)
    ) {
      if (this.child.current !== null) {
        this.child.current.closeChatbot();
      }
    }
  };

  waitTimeLimitOverForLiveAgent = (message, diff) => {
    const { englishBot, liveAgentJoined } = this.state;
    if (
      (diff > 899 && !liveAgentJoined && message.message.includes('The estimated wait time is')) ||
      message.message.includes('El tiempo estimado de espera es')
    ) {
      this.setState({ pegaIntent: false });
      this.handleWrapupMessage(
        {
          text: englishBot
            ? "Sorry! It's been more than 10 minutes waiting and all our agents are currently helping other customers. Please, provide your contact details for us to call you back."
            : '¡Lo siento! Han pasado más de 10 minutos esperando y todos nuestros agentes están asistiendo a otros clientes. Por favor, provee tu información de contacto para devolverle la llamada.',
          value: '',
          showYesNo: true,
          msgFromLex: true,
          messageDate: new Date(),
        },
        false
      );
      this.handlerLiveAgentTrigger();
    }
  };

  handleLanguageChange = (chatLanguage) => {
    let englishBotStatus = true;
    if (chatLanguage === ENG) {
      englishBotStatus = true;
    } else {
      englishBotStatus = false;
    }
    getCountriesList(englishBotStatus, this.handleCountriesList);
    const { i18n } = this.props;
    i18n.changeLanguage(chatLanguage);
    setTimeout(() => {
      this.setState({
        englishBot: englishBotStatus,
        defaultTextContent: localStorage.getItem('textContent')
          ? localStorage.getItem('textContent')
          : '',
      });
    }, 1000);
  };

  handlePopupRunningCase = (flag) => {
    const { popupRunning } = this.state;
    this.setState(() => ({ popupRunning: flag }));
    toggleChatbotContainerSize(popupRunning);
  };

  askingForWaitingTime = () => {
    this.setState({ askForTimeAgain: true });
  };

  formInProcessTrigger = (flag) => {
    this.setState({ formInProcess: flag });
  };

  spinnerHandler = (flag) => {
    this.setState({ spinnerTrigger: flag });
  };

  sendAgentWaitingTimeMessageTrigger = (intialWaitTime, diff) => {
    const { messages, englishBot } = this.state;
    const newMessages = [...messages];
    const content = englishBot
      ? `No problem, the estimated wait time is ${timeConvert(intialWaitTime - diff)} minute(s).`
      : `Por supuesto, el tiempo estimado de espera es ${timeConvert(
          intialWaitTime - diff
        )} minuto (s)`;
    const mesg = { message: content, isOwn: false, msgFromLex: true, messageDate: new Date() };
    newMessages.push(mesg);
    this.setState({ messages: newMessages, askForTimeAgain: false });
  };

  ReconnectToAwsConnect = () => {
    this.AWSConnectInit(cusData);
  };

  setReconnectionStatus = () => {
    this.setState({ reConnect: true });
  };

  AWSConnectInit = async (params) => {
    let agentTransferred = false;
    let agentName = '';
    axios
      .post(
        process.env.REACT_APP_TOKEN_SERVICE_URL,
        {
          clientId: process.env.REACT_APP_CLIENT_ID,
        },
        {
          headers: {
            Authorization: `Basic ${base64Authorization}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        axios
          .post(process.env.REACT_APP_CONNECT_AUTH_URL, params, {
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then(async (res) => {
            cusData = res.data;
            customerChatSession = await connect.ChatSession.create({
              chatDetails: res.data,
              options: {
                region: 'us-east-1',
              },
              type: 'CUSTOMER',
            });
            await customerChatSession.connect().then(
              (resp) => {
                const { customerDetails } = this.state;
                disableAllChatButtons();
                this.setState({ liveAgent: true, liveAgentDisconnected: false });
                let firstMessage = `Hi\n customerName: ${customerDetails.customerName}\n customerEmail: ${customerDetails.customerEmail}\n customerMobile: ${customerDetails.customerMobile}`;
                firstMessage += customerDetails.customerDob
                  ? `\n customerDob: ${customerDetails.customerDob}`
                  : '';
                firstMessage += customerDetails.customerSSN
                  ? `\n customerSSN: ${customerDetails.customerSSN}`
                  : '';
                firstMessage += customerDetails.customerAccountNumber
                  ? `\n customerAccountNumber: ${customerDetails.customerAccountNumber}`
                  : '';
                this.sendToConnect(firstMessage);
                return resp;
              },
              (error) => Promise.reject(error)
            );
            await customerChatSession.onMessage((message) => {
              const { messages, englishBot } = this.state;
              const { data } = message;
              let mesg = {};
              let botEndMessage = '';
              if (
                data.ContentType === 'application/vnd.amazonaws.connect.event.transfer.succeeded'
              ) {
                agentTransferred = true;
              }
              if (
                data.ContentType === 'application/vnd.amazonaws.connect.event.participant.left' &&
                data.ParticipantRole === 'AGENT'
              ) {
                agentName = data.DisplayName;
              }
              if (
                data.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined' &&
                data.ParticipantRole === 'AGENT'
              ) {
                this.setState({
                  liveAgentJoined: true,
                  allowUploadDoc: true,
                  verfiedCustomer: false,
                  awsConnectAgentName: data.DisplayName,
                });
                const oldMessagesObj = [...messages];
                let newMessagesObj = oldMessagesObj;
                newMessagesObj = newMessagesObj.filter(
                  (item) =>
                    !item.message.includes(
                      englishBot ? ALL_AGENTS_BUSY_EST_TIME_ENG : ALL_AGENTS_BUSY_EST_TIME_ESP
                    ) &&
                    !item.message.includes(
                      englishBot ? ESTIMATED_WAIT_TIME_IS : ESTIMATED_WAIT_TIME_IS_ESP
                    ) &&
                    !item.message.includes(
                      englishBot ? WAIT_FOR_LIVE_AGENT_ENG : WAIT_FOR_LIVE_AGENT_ESP
                    )
                );
                if (agentTransferred && agentName) {
                  botEndMessage = englishBot
                    ? `Your chat with ${agentName} has ended!`
                    : `¡Tu chat con ${agentName} ha terminado!`;
                } else {
                  botEndMessage = englishBot ? CHAT_WITH_BOT_ENDED_ENG : CHAT_WITH_BOT_ENDED_ESP;
                }
                const chatEndedMessage = {
                  message: botEndMessage,
                  isOwn: false,
                  msgFromLex: true,
                  messageDate: new Date(),
                };
                newMessagesObj.push(chatEndedMessage);
                const agentEnterMessage = englishBot
                  ? `${data.DisplayName} has entered the chat`
                  : `${data.DisplayName} se ha unido al chat`;
                const chatAgentEnterMessage = {
                  message: agentEnterMessage,
                  isOwn: false,
                  agentEnterMessage: true,
                  messageDate: new Date(),
                };
                newMessagesObj.push(chatAgentEnterMessage);
                setTimeout(() => {
                  this.setState({
                    messages: newMessagesObj,
                    liveAgent: true,
                    waitingForLiveAgent: false,
                  });
                }, 1000);
              }
              if (data.Type !== 'EVENT') {
                if (
                  data.Content &&
                  (data.ParticipantRole === 'SYSTEM' || data.ParticipantRole === 'AGENT')
                ) {
                  if (data.ParticipantRole === 'AGENT') {
                    this.setState({
                      agentTypingFlag: false,
                      allowUploadDoc: true,
                      awsConnectAgentName: data.DisplayName,
                      liveAgent: true,
                    });
                    mesg = {
                      message: data.Content,
                      isOwn: false,
                      msgFromLex: false,
                      messageDate: new Date(),
                    };
                  } else {
                    mesg = {
                      message: data.Content,
                      isOwn: false,
                      msgFromLex: true,
                      messageDate: new Date(),
                    };
                  }
                  this.setState({ waitingForLiveAgent: false });
                  const oldMessages = [...messages];
                  if (
                    oldMessages.length > 0 &&
                    oldMessages[oldMessages.length - 1].message === ''
                  ) {
                    oldMessages.splice(-1, 1);
                  }
                  const newMessages = oldMessages;
                  if (
                    (mesg.message.includes(ALL_AGENTS_BUSY_EST_TIME_ENG) ||
                      mesg.message.includes(ALL_AGENTS_BUSY_EST_TIME_ESP)) &&
                    newMessages.findIndex((item) => item.message === botEndMessage) === -1
                  ) {
                    const num = mesg.message.match(/-?\d+\.?\d*/);
                    intialWaitingTime = parseInt(num[0], 10);
                    if (parseInt(num[0], 10) < 5) {
                      mesg.message = mesg.message.replace(num, timeConvert(parseInt(num[0], 10)));
                    } else {
                      mesg.message = mesg.message.replace(
                        num,
                        englishBot ? MORE_THAN_FIVE_ENG : MORE_THAN_FIVE_ESP
                      );
                    }
                  }
                  if (
                    mesg.message.includes('is not available right now') === false &&
                    mesg.message.includes('no está disponible en este momento') === false &&
                    mesg.message.includes('All Agents are not available right now') === false &&
                    mesg.message.includes('Todos los agentes están ocupados en este momento') ===
                      false &&
                    mesg.message.includes('Transferring now') === false &&
                    mesg.message.includes('Transfiriendo') === false &&
                    mesg.message.includes('Now transferring') === false &&
                    mesg.message.includes('ahora transfiriendo') === false
                  ) {
                    newMessages.push(mesg);
                    setTimeout(() => {
                      this.setState({ messages: newMessages });
                    }, 1000);
                  }
                }
                if (
                  data.Attachments &&
                  data.Attachments.length > 0 &&
                  (data.ParticipantRole === 'SYSTEM' || data.ParticipantRole === 'AGENT')
                ) {
                  const messagesArr = [...messages];
                  const item = messagesArr[messagesArr.length - 1];
                  item.readStatus = true;
                  messagesArr[messagesArr.length - 1] = item;
                  this.setState({ messages: messagesArr });
                  const oldMessages = [...messages];
                  if (
                    oldMessages.length > 0 &&
                    oldMessages[oldMessages.length - 1].message === ''
                  ) {
                    oldMessages.splice(-1, 1);
                  }
                  const recievedFilesMessage = {
                    message: englishBot
                      ? `${data.DisplayName} sent you following attachment:`
                      : `${data.DisplayName} te envió el siguiente documento:`,
                    isOwn: false,
                    msgFromLex: false,
                    messageDate: new Date(),
                  };
                  const newMessages = oldMessages;
                  newMessages.push(recievedFilesMessage);
                  data.Attachments.map(async (Attachment) => {
                    const file = await downloadAttachmentFile(
                      Attachment.AttachmentId,
                      customerChatSession.controller.connectionHelper.connectionDetailsProvider
                        .connectionToken
                    );
                    const mesgItem = {
                      message: Attachment.AttachmentName,
                      messageLink: file,
                      isOwn: false,
                      msgFromLex: false,
                      attachmentFile: true,
                      AttachmentId: Attachment.AttachmentId,
                      messageDate: new Date(),
                    };
                    newMessages.push(mesgItem);
                    this.setState({ messages: newMessages });
                  });
                }
              }
            });

            await customerChatSession.onTyping((typingEvent) => {
              const { messages } = this.state;
              const { data } = typingEvent;
              if (data.ParticipantRole === 'AGENT') {
                this.setState({ agentTypingFlag: true, hideOnCustomerType: false });
                const messagesArr = [...messages];
                for (let i = 1; i < 10; i++) {
                  const item = messagesArr[messagesArr.length - i];
                  item.readStatus = true;
                  messagesArr[messagesArr.length - i] = item;
                  this.setState({ messages: messagesArr });
                }
                const inputText = localStorage.getItem('textContent');
                if (inputText !== null && inputText !== '') {
                  this.sendTypingEventToConnect();
                }
              }
            });

            await customerChatSession.onConnectionBroken((data) => {
              const { messages } = this.state;
              const messagesArr = [...messages];
              const item = messagesArr[messagesArr.length - 1];
              item.sentFailedStatus = true;
              messagesArr[messagesArr.length - 1] = item;
              this.setState({ messages: messagesArr });
            });
            await customerChatSession.onEnded(() => {
              const { messages, englishBot, allowUploadDoc } = this.state;
              const messagesArray = [...messages];
              const message = englishBot ? LIVE_AGENT_CHAT_ENDED_ENG : LIVE_AGENT_CHAT_ENDED_ESP;
              if (
                messagesArray[messagesArray.length - 1].message !== message &&
                messagesArray[messagesArray.length - 1].message !== SORRY_AGENTS_ARE_BUSY_ENG &&
                messagesArray[messagesArray.length - 1].message !== SORRY_AGENTS_BUSY_ESP
              ) {
                this.setState(
                  {
                    liveAgent: false,
                    connectionSucessful: false,
                    liveAgentJoined: false,
                    verfiedCustomer: false,
                    agentTypingFlag: false,
                    waitingForLiveAgent: false,
                    pegaIntent: false,
                    liveAgentDisconnected: true,
                    customerDetails: {},
                  },
                  () => {
                    if (allowUploadDoc) {
                      const oldMesg = [...messages];
                      messages.push({
                        message,
                        messageDate: new Date(),
                        isOwn: false,
                        msgFromLex: true,
                      });
                      this.setState({ messages: oldMesg });
                    }
                  }
                );
                const messagesArr = [...messages];
                for (let i = 1; i < 10; i++) {
                  const item = messagesArr[messagesArr.length - i];
                  item.readStatus = true;
                  messagesArr[messagesArr.length - i] = item;
                  this.setState({ messages: messagesArr });
                }
              }
              this.setState({ allowUploadDoc: false });
            });
          })
          .catch((err) => {
            const { messages, englishBot } = this.state;
            const content = englishBot ? SOMETHING_WENT_WRONG_ENG : SOMETHING_WENT_WRONG_ESP;
            const messagesArr = [...messages];
            const mesg = {
              message: content,
              isOwn: false,
              msgFromLex: true,
              messageDate: new Date(),
            };
            if (messagesArr[messagesArr.length - 1]?.message === '') {
              messagesArr.splice(-1, 1);
            }
            messagesArr.push(mesg);
            this.setState({
              messages: messagesArr,
              waitingForLiveAgent: false,
            });
          });
      })
      .catch();
  };

  handleNewUserMessage = (newMessage, ownMessage) => {
    const {
      messages,
      liveAgent,
      englishBot,
      liveAgentDisconnected,
      agentTypingFlag,
      sspSiteFlag,
      btnIntentTrigger,
    } = this.state;
    const message = englishBot ? LIVE_AGENT_CHAT_ENDED_ENG : LIVE_AGENT_CHAT_ENDED_ESP;
    if (messages.length === 0) {
      // if(!(newMessage.text === 'PEGA_CONNECT'))
      if (sspSiteFlag === 'true' || sspSiteFlag === true) {
        this.setState({
          messages: [
            ...messages,
            {
              message: newMessage.text,
              isOwn: true,
              msgForAgent: false,
              readStatus: false,
              sentFailedStatus: false,
              showYesNo: newMessage.showYesNo,
              messageDate: new Date(),
            },
            {
              message: '',
              isOwn: ownMessage,
              showYesNo: false,
            },
          ],
        });
      } else {
        this.setState({
          messages: [
            ...messages,
            {
              message: '',
              isOwn: ownMessage,
              msgForAgent: liveAgent,
              readStatus: false,
              sentFailedStatus: false,
              messageDate: new Date(),
              showYesNo: false,
            },
          ],
        });
      }
    } else if (newMessage.value === message) {
      if (!liveAgentDisconnected) {
        this.setState({
          messages: [
            ...messages,
            {
              message: newMessage.text,
              isOwn: ownMessage,
              msgForAgent: liveAgent,
              readStatus: false,
              sentFailedStatus: false,
              showYesNo: newMessage.showYesNo,
              messageDate: new Date(),
            },
          ],
        });
      }
    } else if (newMessage.text !== 'PEGA_CONNECT') {
      liveAgent
        ? this.setState({
            messages: [
              ...messages,
              {
                message: newMessage.text,
                isOwn: ownMessage,
                msgForAgent: liveAgent,
                readStatus: !!agentTypingFlag,
                sentFailedStatus: false,
                showYesNo: newMessage.showYesNo,
                messageDate: new Date(),
              },
            ],
          })
        : this.setState({
            messages: [
              ...messages,
              {
                message: newMessage.text,
                isOwn: ownMessage,
                msgForAgent: liveAgent,
                readStatus: false,
                sentFailedStatus: false,
                showYesNo: newMessage.showYesNo,
                messageDate: new Date(),
              },
              {
                message: '',
                isOwn: ownMessage,
                showYesNo: false,
              },
            ],
          });
    } else {
      this.setState({
        messages: [
          ...messages,
          {
            message: '',
            isOwn: ownMessage,
            msgForAgent: liveAgent,
            readStatus: false,
            sentFailedStatus: false,
            showYesNo: false,
            messageDate: new Date(),
          },
        ],
      });
    }
    let isServiceCase = false;
    if (
      newMessage.type ||
      newMessage.value === 'search_by_ssn_again' ||
      newMessage.value.includes('*LoadMore')
    ) {
      isServiceCase = true;
    }
    if (liveAgent) {
      this.sendToConnect(newMessage.value);
    } else if (newMessage.value !== message) {
      setTimeout(() => {
        this.sendToLex(newMessage.value, isServiceCase);
      }, 200);
    }
  };

  handleWrapupMessage = (newMessage, ownMessage, customData = {}) => {
    const { messages, liveAgent } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage.text,
          isOwn: ownMessage,
          msgForAgent: liveAgent,
          msgFromLex: true,
          readStatus: false,
          sentFailedStatus: false,
          showYesNo: newMessage.showYesNo,
          showWrapupStepTwo: newMessage.showWrapupStepTwo,
          messageDate: newMessage.messageDate,
          ...customData,
        },
      ],
    });
  };

  handeLanguageChangeMessage = (newMessage, ownMessage) => {
    const { messages, englishBot, liveAgent } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage.text,
          isOwn: ownMessage,
          chatLanguage: englishBot ? ENG : ESP,
          msgForAgent: liveAgent,
          msgFromLex: true,
          readStatus: true,
          sentFailedStatus: false,
          languageChangeYesNo: newMessage.languageChangeYesNo,
        },
      ],
    });
  };

  handeButtonLinkMessage = (newMessage, ownMessage) => {
    const { messages, liveAgentJoined } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage.text,
          isOwn: ownMessage,
          msgFromLex: !liveAgentJoined,
          readStatus: true,
          sentFailedStatus: false,
          isButtonMessage: newMessage.isButtonMessage,
          qrCode: newMessage.qrCode,
          messageDate: new Date(),
        },
      ],
    });
  };

  handleButtonClickDuringAgentCallMessage = (newMessage, ownMessage) => {
    const { messages, liveAgent, liveAgentJoined } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage?.text,
          isOwn: ownMessage,
          msgForAgent: liveAgent,
          msgFromLex: !liveAgentJoined,
          readStatus: false,
          sentFailedStatus: false,
          showYesNo: newMessage?.showYesNo,
          showWrapupStepTwo: newMessage?.showWrapupStepTwo,
          messageDate: newMessage?.messageDate,
        },
      ],
    });
  };

  handleDateMessage = (newMessage, ownMessage) => {
    const { messages } = this.state;
    this.dateReceivedHandler();
    this.setState(
      {
        messages: [
          ...messages,
          {
            message: newMessage.text,
            isOwn: ownMessage,
            msgForAgent: false,
            readStatus: false,
            sentFailedStatus: false,
            showStartDateCalendar: newMessage.showStartDateCalendar,
            showEndDateCalendar: newMessage.showEndDateCalendar,
          },
        ],
      },
      () => {
        if (!newMessage.showEndDateCalendar) {
          this.spinnerHandler(true);
          this.sendToLex(newMessage.value);
        }
      }
    );
  };

  dateReceivedHandler = () => {
    const { messages } = this.state;
    const messagesArr = [...messages];
    const item = messagesArr[messagesArr.length - 1];
    if (item) {
      item.dateReceived = true;
      messagesArr[messagesArr.length - 1] = item;
      this.setState({ messages: messagesArr });
    }
  };

  setUserEmailFromIntent = (email) => {
    this.setState({ userEmail: email });
  };

  setVerifedCustomer = (flag) => {
    this.setState({ verfiedCustomer: flag });
  };

  handlerPageIntent = () => {
    this.setState({ pegaIntent: false });
  };

  handlerIntentTrigger = () => {
    this.setState({ btnIntentTrigger: '' });
  };

  setCustomerDetails = (data) => {
    this.setState({ customerDetails: data });
  };

  handlerLiveAgentTrigger = () => {
    this.setState(
      {
        liveAgent: false,
        connectionSucessful: false,
        liveAgentJoined: false,
        liveAgentDisconnected: true,
        waitingForLiveAgent: false,
        customerDetails: {},
      },
      () => {
        if (customerChatSession.controller) {
          customerChatSession.disconnectParticipant();
        }
      }
    );
  };

  sendToLex = (message, isServiceCase, uuid, afterPostLexCall) => {
    const { slotToElicit, englishBot, sessionID, chatDeviceType, chatDeviceOS } = this.state;
    const params = getParameters(
      message,
      slotToElicit,
      englishBot,
      sessionID,
      chatDeviceType,
      chatDeviceOS,
      SspUserLogin,
      uuid
    );
    axios
      .post(
        process.env.REACT_APP_TOKEN_SERVICE_URL,
        {
          clientId: process.env.REACT_APP_CLIENT_ID,
        },
        {
          headers: {
            Authorization: `Basic ${base64Authorization}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        this.setState({ postLexToken: response?.data?.access_token });
        axios
          .post(`${process.env.REACT_APP_POST_CALL_API}`, params, {
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            const { data } = res;
            this.lexSuccessHandler(data, isServiceCase);
            if (afterPostLexCall) afterPostLexCall();
          })
          .catch((err) => {
            const { messages } = this.state;
            const oldMessages = [...messages];
            if (oldMessages.length > 0 && oldMessages[oldMessages.length - 1].message === '') {
              oldMessages.splice(-1, 1);
            }
            const newMessages = oldMessages;
            setTimeout(() => {
              this.setState({ messages: newMessages }, () => {
                this.lexFailedHandler(err);
              });
            }, 100);
          });
      })
      .catch();
  };

  hideAttachmentLoader = () => {
    this.setState({ attachmentLoader: false });
  };

  attachmentListPost = (uuidArr) => {
    this.sendToLex('ATTACHMENT_LIST', false, uuidArr, this.hideAttachmentLoader);
  };

  sendAttachments = async (attachments) => {
    const { sessionID } = this.state;
    this.setState({ attachmentLoader: true });

    setTimeout(() => {
      const messageContainer = document.getElementById('messageContainer');
      if (messageContainer) {
        messageContainer.scrollTop = messageContainer.scrollHeight - messageContainer.clientHeight;
      }
    }, 1000);

    const attachmentList = attachments.filter((attachment) => attachment !== false);

    const { postLexToken } = this.state;

    const lessThan4mbUUIDArr = [];
    const greaterThan4mbUUIDArr = [];
    const allUUIDs = [];
    for (const attachment of attachmentList) {
      const formData = new FormData();
      formData.append('Document', attachment);

      if (attachment.size > 4194304) {
        const uuid = generateuuid();
        allUUIDs.push(uuid);
        greaterThan4mbUUIDArr.push(uuid);
        // eslint-disable-next-line no-await-in-loop
        const fileMd5 = await generateFileData(attachment);
        const fileData = {
          contentMD5: fileMd5,
          fileName: attachment.name,
          fileSize: attachment.size,
          file: attachment,
        };
        // eslint-disable-next-line no-await-in-loop, react/destructuring-assignment
        await postFileUpload(fileData, uuid, postLexToken, sessionID, 'callback');
      } else {
        const uuid = generateuuid();
        allUUIDs.push(uuid);
        lessThan4mbUUIDArr.push(uuid);
        const { englishBot } = this.state;
        const fileData = {
          fileName: attachment.name,
          size: attachment.size,
          source: 'Chatbot',
          format: 'pdf',
          eventName: 'CB_REQUEST_CALLBACK',
          info: 'test',
          lang: englishBot ? 'EN' : 'ES',
        };
        // eslint-disable-next-line no-await-in-loop, react/destructuring-assignment
        await postAttachments(sessionID, uuid, formData, this.state.postLexToken, fileData);
      }
    }
    this.attachmentListPost(allUUIDs);
  };

  lexSuccessHandler = (data, isServiceCase) => {
    const { messages } = this.state;
    if (data) {
      this.spinnerHandler(false);
      let mesg = {};
      if (data.sessionState.sessionAttributes.validDebitCard === 'true') {
        this.setState({ cardValidFlag: true });
      } else {
        this.setState({ cardValidFlag: false });
      }
      if (data.messages && data.messages[0].content.includes('templateType')) {
        const listPickerData = JSON.parse(data.messages[0].content);
        this.setState({ sessionID: listPickerData.data.content.sessionId });
        if (listPickerData.data.content.intentName !== null) {
          if (
            listPickerData.data.content.intentName === 'CB_LiveAgent_Intent' ||
            listPickerData.data.content.intentName === 'CB_LiveAgent_Intent_esp'
          ) {
            this.setState({ pegaIntent: true });
          } else if (
            intentArrWithPegaTrue.includes(listPickerData.data.content.intentName) &&
            listPickerData.data.content.slotToElicit &&
            listPickerData.data.content.slotToElicit !== null
          ) {
            this.setState({ pegaIntent: true });
            this.setState({ slotToElicit: listPickerData.data.content.slotToElicit });
          } else if (
            intentArrWithPegaFalse.includes(listPickerData.data.content.intentName) &&
            listPickerData.data.content.slotToElicit &&
            listPickerData.data.content.slotToElicit !== null
          ) {
            this.setState({ slotToElicit: listPickerData.data.content.slotToElicit });
          } else {
            this.setState({ pegaIntent: false });
            this.setState({ slotToElicit: '' });
          }
        }
        if (listPickerData.data.content.title.includes('Your request is processing.')) {
          this.handleNewUserMessage({ text: 'Inprogress', value: 'Inprogress' }, true);
        } else if (
          listPickerData.data.content.title !== 'Thank you!' &&
          listPickerData.data.content.title !== '¡Gracias!'
        ) {
          mesg = {
            message: listPickerData.data.content.title,
            msgFromLex: true,
            isOwn: false,
            intentName: listPickerData.data.content.intentName,
            slotToElicit: listPickerData.data.content.slotToElicit,
            messageDate: new Date(),
          };
          if (listPickerData.data.content.elements[0].title !== ' ') {
            mesg.responseCard = listPickerData.data.content.elements;
            mesg.responseCard.isServiceCase = isServiceCase;
          }
        }
      } else {
        mesg = {
          message: data.Content,
          isOwn: false,
          msgFromLex: true,
          messageDate: new Date(),
        };
      }
      const oldMessages = [...messages];
      if (oldMessages.length > 0 && oldMessages[oldMessages.length - 1].message === '') {
        oldMessages.splice(-1, 1);
      }
      const newMessages = oldMessages;
      if (Object.keys(mesg).length !== 0) {
        newMessages.push(mesg);
      }
      setTimeout(() => {
        this.setState({ messages: newMessages }, () => {
          focusInChatbot();
        });
      }, 100);
    }
  };

  lexFailedHandler = (err) => {
    const { englishBot, messages } = this.state;
    if (err.message === 'Request failed with status code 504') {
      this.handleNewUserMessage({ text: 'Inprogress', value: 'Inprogress' }, true);
      return;
    }
    if (err && err.message !== 'Request failed with status code 504') {
      const botMessage = englishBot ? TRY_AGAIN_LATER_ENG : TRY_AGAIN_LATER_ESP;
      const mesg = {
        message: botMessage,
        msgFromLex: true,
        isOwn: false,
        messageDate: new Date(),
      };
      const buttonText = englishBot ? CONTACT_US_ENG : CONTACT_US_ESP;
      const buttonValue = englishBot
        ? 'https://orientalbank.com/en/contactus/'
        : 'https://orientalbank.com/es/contactanos/';
      mesg.responseCard = [{ title: buttonText, subtitle: buttonValue }];
      const newMessages = [...messages];
      if (
        newMessages[newMessages.length - 1] &&
        newMessages[newMessages.length - 1].message === ''
      ) {
        newMessages.splice(-1, 1);
      }
      newMessages.push(mesg);
      this.setState({ messages: newMessages, lexError: true }, () => {
        focusInChatbot();
      });
    } else {
      this.setState({ lexError: false });
    }
  };

  deleteSessionToLex = () => {
    const { englishBot, sessionID } = this.state;
    deleteSessioApiCall(englishBot, sessionID);
    this.setState({ sessionID: '', spinnerTrigger: false });
  };

  /* eslint-disable class-methods-use-this */
  sendDocToConnect = (docAttachment) => {
    if (customerChatSession) {
      customerChatSession.sendAttachment({
        attachment: docAttachment,
      });
    }
  };
  /* eslint-enable class-methods-use-this */

  sendTypingEventToConnect = () => {
    const { agentTypingFlag, liveAgentJoined, liveAgent } = this.state;
    if (agentTypingFlag === false) {
      this.setState({ hideOnCustomerType: true });
    }
    if (customerChatSession && liveAgentJoined && liveAgent) {
      customerChatSession.sendEvent({
        contentType: 'application/vnd.amazonaws.connect.event.typing',
      });
    }
  };

  sendToConnect = async (message) => {
    this.setState({ hideOnCustomerType: false });
    await customerChatSession.controller.sendMessage({
      message,
      contentType: 'text/plain',
      options: {
        waitInQ: message,
      },
    });
  };

  uploadDocuments = (attachments, selectedFileWithRemovedData, removedAll, removeFilesList) => {
    if (!removedAll) {
      const updatedList = attachments.filter(
        (attachment, index) =>
          !(selectedFileWithRemovedData[index] && selectedFileWithRemovedData[index].undo)
      );
      if (updatedList.length > 0) {
        this.sendAttachments(updatedList);
      } else {
        this.cancelUploadDocuments();
      }
    } else {
      const removedFileNames = removeFilesList.map((file) => file.name);
      const updatedList = attachments.filter((file) => !removedFileNames.includes(file.name));
      if (updatedList.length > 0) {
        this.sendAttachments(updatedList);
      } else {
        this.cancelUploadDocuments();
      }
    }
  };

  cancelUploadDocuments = () => {
    this.setState({ attachmentLoader: true });
    this.sendToLex('NO_ATTACHMENT', false, [], this.hideAttachmentLoader);
  };

  addMessages = (data) => {
    const { messages } = this.state;
    this.setState({
      messages: [...messages, { message: data, isOwn: true }, { message: '', isOwn: false }],
    });
    this.sendToLex(data);
  };

  clearMessage = () => {
    const { originalBotLanguage } = this.state;
    this.setState({
      englishBot: originalBotLanguage,
      messages: [],
      pegaIntent: false,
      slotToElicit: '',
      btnIntentTrigger: '',
    });
  };

  render() {
    const {
      sessionID,
      reConnect,
      gotGreeting,
      connectionSucessful,
      btnIntentTrigger,
      verfiedCustomer,
      userEmail,
      hideOnCustomerType,
      liveAgentJoined,
      agentTypingFlag,
      customerDetails,
      awsConnectAgentName,
      allowUploadDoc,
      liveAgent,
      waitingForLiveAgent,
      pegaIntent,
      slotToElicit,
      defaultTextContent,
      englishBot,
      lexError,
      messages,
      countriesList,
      spinnerTrigger,
      cardValidFlag,
      sspSiteFlag,
    } = this.state;
    return (
      <ThemeProvider theme={this.theme}>
        <div role="main">
          <FixedWrapper.Root>
            <FixedWrapper.Maximized
              className={`main-wrapper ${deviceOS === 'IOS' ? 'device-iphone' : ''}`}
            >
              <Maximized
                ref={this.child}
                sessionID={sessionID}
                cardValidFlag={cardValidFlag}
                sendToLex={this.sendToLex}
                setReconnectionStatus={this.setReconnectionStatus}
                reConnect={reConnect}
                gotGreeting={gotGreeting}
                spinnerTrigger={spinnerTrigger}
                connectionSucessful={connectionSucessful}
                btnIntentTrigger={btnIntentTrigger}
                verfiedCustomer={verfiedCustomer}
                setVerifedCustomer={this.setVerifedCustomer}
                userEmail={userEmail}
                setUserEmailFromIntent={this.setUserEmailFromIntent}
                hideOnCustomerType={hideOnCustomerType}
                sendTypingEventToConnect={this.sendTypingEventToConnect}
                liveAgentJoined={liveAgentJoined}
                askingForWaitingTime={this.askingForWaitingTime}
                handleButtonClickDuringAgentCallMessage={
                  this.handleButtonClickDuringAgentCallMessage
                }
                agentTypingFlag={agentTypingFlag}
                customerDetails={customerDetails}
                awsConnectAgentName={awsConnectAgentName}
                allowUploadDoc={allowUploadDoc}
                runningAWSConnect={liveAgent}
                waitingForLiveAgent={waitingForLiveAgent}
                sendDocToConnect={this.sendDocToConnect}
                ReconnectToAwsConnect={this.ReconnectToAwsConnect}
                handlerLiveAgentTrigger={this.handlerLiveAgentTrigger}
                pegaIntent={pegaIntent}
                slotToElicit={slotToElicit}
                handlerPageIntent={this.handlerPageIntent}
                handlerIntentTrigger={this.handlerIntentTrigger}
                defaultTextareaContent={defaultTextContent}
                englishBot={englishBot}
                lexError={lexError}
                messages={messages}
                countriesList={countriesList}
                sspSiteFlag={sspSiteFlag}
                handleCountriesList={this.handleCountriesList}
                deleteSessionToLex={this.deleteSessionToLex}
                handleLanguageChange={this.handleLanguageChange}
                handleMessage={this.handleNewUserMessage}
                handleWrapupMessage={this.handleWrapupMessage}
                handlePopupRunningCase={this.handlePopupRunningCase}
                handeLanguageChangeMessage={this.handeLanguageChangeMessage}
                handeButtonLinkMessage={this.handeButtonLinkMessage}
                handleDateMessage={this.handleDateMessage}
                addMessages={this.addMessages}
                uploadDocuments={this.uploadDocuments}
                cancelUploadDocuments={this.cancelUploadDocuments}
                onCloseClick={this.clearMessage}
                formInProcessTrigger={this.formInProcessTrigger}
                setCustomerDetails={this.setCustomerDetails}
                spinnerHandler={this.spinnerHandler}
                sendToConnect={this.sendToConnect}
                // eslint-disable-next-line react/destructuring-assignment
                attachmentLoader={this.state.attachmentLoader}
                {...this.props}
              />
            </FixedWrapper.Maximized>
            <FixedWrapper.Minimized className="main-wrapper-minimize">
              <Minimized
                {...this.props}
                btnIntentTrigger={btnIntentTrigger}
                handlerIntentTrigger={this.handlerIntentTrigger}
              />
            </FixedWrapper.Minimized>
          </FixedWrapper.Root>
        </div>
      </ThemeProvider>
    );
  }
}
export default withTranslation()(App);

App.propTypes = {
  i18n: PropTypes.object,
};
